/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import './AddStrategyForm.scss';
import { Icon, Spinner, Callout } from '@blueprintjs/core';
import Checkbox from '@mui/material/Checkbox';
import InfoBar from '../../../InfoBar/InfoBar';
import { useIntl } from 'react-intl';
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from 'react-redux';
import { getDomains } from '../../../../../../store/domains/actions';
import { Strategy } from '../../../../../../types';
import { getStrategies } from '../../../../../../store/strategies/actions';
import { AuthRoutes } from '../../../../../../App';
import { generatePath, useHistory } from 'react-router';
import { addStrategyToPlan, getPlanStrategies } from '../../../../../../store/plan-strategies/actions';
import { Link } from 'react-router-dom';
import CoreComponents from '../../../core-components/CoreComponents';
import StrategyDetails from '../strategy-details/StrategyDetails';
import { getGoalsByPlan } from '../../../../../../store/goals/actions';
import StrategicDisclaimer from '../../../strategic-disclaimer/StrategicDisclaimer';

type OwnProps = {};
type Props = OwnProps;

const MIN_STRATEGY_PER_LOAD = 6;

const AddStrategyForm: FunctionComponent<Props> = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();

    // STATE
    const [displayedStrategies, setDisplayedStrategies] = useState<any[]>([]);
    const [isShowRecommendDomain, setIsShowRecommendDomain] = useState<boolean>(true);
    const [displayedItems, setDisplayedItems] = useState<number>(MIN_STRATEGY_PER_LOAD);
    const [strategyChecked, setStrategyChecked] = useState<number[]>([]);
    const [warningMessage, setWarningMessage] = useState<boolean>(false);
    
    // Get from redux store
    const strategiesAll = useSelector((s) => s.strategies.strategies);
    const loading = useSelector((s) => s.strategies.loading.getStrategies);
    const activePlanId = useSelector((s) => s.plans.activePlan?.id);
    const activePlanSchoolId = useSelector((s) => s.plans.activePlan?.school?.id);
    const activePlanDistrictId = useSelector((s) => s.plans.activePlan?.district?.id);
    const planStrategies = useSelector((s) => s.planStrategies.planStrategies);
    const addplanStrategiesLoading = useSelector((s) => s.planStrategies.loading.addStrategyToPlan);
    const { goals } = useSelector((s) => s.goals);

    const strategies = useMemo(() => {
        return strategiesAll.filter(stratrgy => stratrgy.is_global || stratrgy.school === activePlanSchoolId || stratrgy.district === activePlanDistrictId);
    }, [activePlanDistrictId, activePlanSchoolId, strategiesAll]);

    const filteredStrategies = useMemo(() => {
        let filteredStrategies: any[] = [];
        strategies.forEach(strategy => {
            let isDuplicate = planStrategies.some(ps => ps.strategy.id === strategy.id);
            if (!isDuplicate) {
                filteredStrategies.push(strategy);
            }
        })
        return filteredStrategies;
    }, [planStrategies, strategies,loading]);
        
    const districtName = useSelector((s) => s.districts.selectedDistrict?.name);

    // Criteria for ranking list of strategies - start
    const domainsGoalActivePlan = useMemo(() => {
        const domainIds = goals.flatMap((goal) => goal.selected_domains?.map(domain => domain.id) || []);
        const uniqueDomainIds = Array.from(new Set(domainIds));

        return uniqueDomainIds;
    }, [goals]);

    // Calculate the number of domain matches for each strategy
    const rankedStrategies = filteredStrategies.map((strategy) => {
        const domainMatches = strategy.domains.filter((domain: { id: number; }) => domainsGoalActivePlan.includes(domain.id));
        return { ...strategy, domainMatches: domainMatches.length };
    });

    // Sort the strategies based on the number of domain matches
    rankedStrategies.sort((a, b) => b.domainMatches - a.domainMatches);
    // Criteria for ranking list of strategies - end

    useEffect(() => {
        dispatch(getDomains.request());
        dispatch(getStrategies.request());

        if (activePlanId) {
            dispatch(getPlanStrategies.request(activePlanId));
        }
    }, [activePlanId, dispatch,addplanStrategiesLoading]);

    const recomStrategies = useMemo(() => {
        return rankedStrategies.filter(strategy => strategy.domainMatches)
    }, [rankedStrategies,loading]);

    useEffect(() => {
        displayedStrategies.map((strategy) =>{
            setSelectedStrategyCard(strategy);
        })
        if (activePlanId) {
            if (isShowRecommendDomain) {
                setDisplayedStrategies(recomStrategies);
                setSelectedStrategyCard(recomStrategies[0]);
            }
            else {
                setDisplayedStrategies(rankedStrategies)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShowRecommendDomain,loading]);

    const [selectedStrategyCard, setSelectedStrategyCard] = useState<Strategy>(isShowRecommendDomain ? recomStrategies[0] : filteredStrategies[0]);

    useEffect(() => {
      
        if (displayedStrategies.length > 0) {
            setSelectedStrategyCard(displayedStrategies[0]);
          
            if(displayedStrategies.find((s)=>s.domainMatches)){
                setIsShowRecommendDomain(true);
            }
            else{
                setIsShowRecommendDomain(false);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const canNavigate = () => {
        history.push(
            generatePath(AuthRoutes.YourPlan, {
                workspace: "goals" as any,
                tabId: "strategies"
            } as any)
        );
    }

    const handleCreateCustomStrategy = () => {
        history.push(
            generatePath(AuthRoutes.YourPlan, {
                workspace: "goals" as any,
                tabId: "custom-strategy"
            } as any)
        );
    }

    const handleListItemClick = (strategy: any) => {
        setSelectedStrategyCard(strategy);
    }

    const handleSelectedStrategies = (strategyId: number) => () => {
        const currentStrategyIndex = strategyChecked.indexOf(strategyId);
        const newStrategyChecked = [...strategyChecked];

        if (currentStrategyIndex === -1) {
            newStrategyChecked.push(strategyId);
        } else {
            newStrategyChecked.splice(currentStrategyIndex, 1);
        }

        setStrategyChecked(newStrategyChecked);
    };

    useEffect(() => {
        activePlanId &&
            dispatch(getGoalsByPlan.request(activePlanId));

        if (!goals.length) {
            setWarningMessage(true);
            setIsShowRecommendDomain(false);
        }
        else {
            setWarningMessage(false);
            setIsShowRecommendDomain(true);
        }
        setIsShowRecommendDomain(false);// make recommended strategies checkbox unchecked on first load
        
    }, [activePlanId, dispatch, goals.length, intl]);

    const handleAddToPlan = () => {
        const params = {
            status: undefined,
            target_groups: undefined,
            grades: undefined,
            launch_date: undefined,
            locations: undefined,
            timeframe: undefined,
            general_approach: undefined,
            leaders: [],
        };

        strategyChecked.forEach(strategyCheckedItem => {
            if (activePlanId && strategyCheckedItem) {
                dispatch(
                    addStrategyToPlan.request({
                        planStrategy: {
                            plan_id: activePlanId,
                            strategy: strategyCheckedItem,
                            ...params,
                        },
                        onSuccess: () => true
                    })
                );
                canNavigate();
            }
        });
    };

    useEffect(() => {  
        if((!goals.length || goals.length) && !isShowRecommendDomain && displayedStrategies.length > 0) {
            setSelectedStrategyCard(displayedStrategies[0]);
        }
    }, [goals,isShowRecommendDomain,displayedStrategies]);

    if (loading) {
        return <Spinner intent="primary" className="my-2" />;
    }

    return (
        <>
            <Helmet>
                <title>{intl.formatMessage({ id: "app.titles.strategic-plan" })}</title>
            </Helmet>
            <div className="add-strategic strategic-plan">
                <div className="breadcrumbs">
                    <ul>
                        <li>
                            <Link to={generatePath(AuthRoutes.YourPlan,
                                { workspace: "dashboard" as any, } as any)}>
                                {intl.formatMessage({ id: "app.titles.dashboard" })}
                            </Link>
                        </li>
                        <li><span>/</span></li>
                        <li><a onClick={canNavigate}>{intl.formatMessage({ id: "app.titles.strategic-plan" })}</a></li>
                        <li><span>/</span></li>
                        <li>{intl.formatMessage({ id: "app.strategies.add-strategy" })}</li>
                    </ul>
                </div>

                {
                    warningMessage &&
                    <div className="my-3 px-5">
                            <Callout intent="warning" className='warning-no-goal'>
                                <span>{intl.formatMessage(
                                    {
                                        id: "app.strategy.warning-message-no-goal",
                                    },
                                    {
                                        b: (chunks) => <b>{chunks}</b>,
                                    }
                                )}</span>
                                <Icon icon="cross"
                                    style={{ float: "right", cursor: "pointer" }}
                                    onClick={() => { setWarningMessage(false) }}
                                />
                            </Callout>
                    </div>
                }

                <div className="toolbar">
                    <h1>{intl.formatMessage({ id: "app.strategies.add-strategy" })}</h1>
                    <ul>
                        <li>
                            <InfoBar />
                        </li>
                    </ul>
                </div>
                {filteredStrategies.length !== 0 ?
                    <>
                        <div className="disclaimer-top-rigth">
                            <StrategicDisclaimer />
                        </div>
                        <div className={"show-recom " + (!goals.length ? "disabled-show-recom " : "")}>
                        <Checkbox checked={isShowRecommendDomain} onChange={() => setIsShowRecommendDomain(!isShowRecommendDomain)} disabled={!goals.length} /> {intl.formatMessage({ id: "app.strategy.recommended-lable" })}
                    </div><div className="add-strategic__content">
                            <div className="cnt-bar">
                                <div className="nj-row">
                                    <div className="nj-sm-6">
                                        <div className="strategies-list">
                                            <h4>{`Strategies (${displayedStrategies.length})`}
                                            </h4>
                                            <label className="star-icon"><Icon icon="star" iconSize={16} /> {intl.formatMessage({ id: "app.strategy.recommended" })}</label>
                                            <ul>
                                                {displayedStrategies && displayedStrategies.map((strategy, index) => {
                                                    return isShowRecommendDomain ?
                                                        (
                                                            !!strategy.domainMatches &&
                                                            <li key={strategy.id} className={selectedStrategyCard?.id === strategy.id ? 'selected-item-strategy' : ""}
                                                                onClick={(event) => handleListItemClick(strategy)}>
                                                                <div className="strategies-list__topbar">
                                                                    <h3>{strategy.name}
                                                                    {strategy.district &&
                                                                    <span className="custom-strategy-district">{intl.formatMessage({ id: "app.custom-strategy" }, { school: districtName })}</span>}</h3>
                                                                    <ul>
                                                                        <li className="star-icon"><Icon icon="star" iconSize={16} /> </li>
                                                                            <li>
                                                                                <Checkbox onChange={handleSelectedStrategies(strategy.id)} checked={strategyChecked.indexOf(strategy.id) !== -1} />
                                                                            </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="bluetext">
                                                                    <CoreComponents key={strategy.id} strategy={strategy} />
                                                                </div>
                                                            </li>
                                                        )
                                                        :
                                                        (
                                                            <li key={strategy.id} className={selectedStrategyCard?.id === strategy.id ? 'selected-item-strategy' : ""}
                                                                onClick={(event) => handleListItemClick(strategy)}>
                                                                <div className="strategies-list__topbar">
                                                                    <h3>{strategy.name}
                                                                    {strategy.district &&
                                                                    <span className="custom-strategy-district">{intl.formatMessage({ id: "app.custom-strategy" }, { school: districtName })}</span>}</h3>
                                                                    <ul>
                                                                        {
                                                                            !!strategy.domainMatches &&
                                                                            <li className="star-icon"><Icon icon="star" iconSize={16} /> </li>
                                                                        }
                                                                        <li>
                                                                            <Checkbox onChange={handleSelectedStrategies(strategy.id)} checked={strategyChecked.indexOf(strategy.id) !== -1} />
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="bluetext">
                                                                    <CoreComponents key={strategy.id} strategy={strategy} />
                                                                </div>
                                                            </li>
                                                        )
                                                })}

                                            </ul>
                                            {/* {displayedItems < displayedStrategies.length && (
                                                <div className="loadmore">
                                                    <a className="stroke-btn" onClick={() => setDisplayedItems(displayedItems + MIN_STRATEGY_PER_LOAD)}>
                                                        {intl.formatMessage({ id: "app.title.load-more" })}
                                                    </a>
                                                </div>
                                            )} */}
                                        </div>
                                    </div>
                                    {
                                        selectedStrategyCard && 
                                        <StrategyDetails selectedStrategy={selectedStrategyCard} />
                                    }
                                </div>

                                <div className="action-bar">
                                    <button onClick={handleCreateCustomStrategy} className="nj_btn large-btn">
                                       <Icon icon="plus" iconSize={16} /> {intl.formatMessage({ id: "app.title.add-custom-strategy" })}
                                    </button>

                                    <div className="button-group">
                                        <button className="stroke-btn large-btn" onClick={canNavigate}>
                                            {intl.formatMessage({ id: "app.titles.cancel" })}
                                        </button>

                                        <button onClick={handleAddToPlan} className="nj_btn large-btn" disabled={!!strategyChecked.length ? false : true}>
                                            {intl.formatMessage({ id: "app.title.strategy-library.add-to-plan" })}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div></> :
                        <>
                        <div className="add-strategic__content">
                            <div className="notfound-message no-stragies-found">
                                <div className="notfound-message_inner">
                                    <p>
                                        {intl.formatMessage(
                                            {
                                                id: "app.title.no-strategy-left",
                                            },
                                            {
                                                br: <br />,
                                            }
                                            
                                        )}
                                    </p>
                                </div>
                                <div className="action-bar">
                                    <button className="nj_btn large-btn" onClick={handleCreateCustomStrategy}>
                                        <Icon icon="plus" iconSize={16} /> {intl.formatMessage({ id: "app.title.add-custom-strategy" })}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    );
}

export default AddStrategyForm;