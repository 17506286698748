import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Callout, NonIdealState, Spinner } from "@blueprintjs/core";
import useComparison from "../../../surveys/tab/useComparison";
import { Domain, DomainComparison, SurveyDemographic, ArrayGroups, DomainPriorityTriageStatus, DomainDemographicTriageStatuses } from "../../../../types";
import DomainComparisonCard from "./DomainComparisonCard";
import _, { slice } from "lodash";
import { useLoadingWithDependencies } from "../../../../helpers/hooks/loading/useLoadingWithDependencies";
import DemographicEyeIcon from "./DemographicEyeIcon";
import { array } from "prop-types";
import Grid from "@mui/material/Grid";

type OwnProps = {
  surveyDeploymentId?: number;
  showRedBorder?: boolean;
  isPriorityConfirmed?: boolean;
};

type Props = OwnProps;

const ExploreStrengthsAndNeedsTab: FunctionComponent<Props> = (props) => {
  const { surveyDeploymentId, showRedBorder, isPriorityConfirmed } = props;

  const intl = useIntl();

  const surveyComparison = useSelector((s) => s.surveyReports.surveyComparison);

  const loadingGetSurveyComparison = useSelector(
    (s) => s.surveyReports.loading.getSurveyComparison
  );

  const domainPriorities = useSelector((s) => s.needs.domainPriorities);

  const loadingGetDomainPriorities = useSelector(
    (s) => s.needs.loading.getDomainPriorities
  );
  const loadingGetDatasetMarks = useSelector(
    (s) => s.datasetMarks.loading.getDatasetMarks
  );

  const { demographics } = useComparison({
    surveyComparison: surveyComparison,
  });

  const triageStatusesDictionary: {
    [domainId: string]: DomainDemographicTriageStatuses;
  } = useMemo(() => {
    return _.chain(domainPriorities)
      .groupBy((dP) => dP.domain.id)
      .mapValues<DomainDemographicTriageStatuses>((dPriorities) => {
        const triageStatusByDemographic: {
          [key in SurveyDemographic]?: DomainPriorityTriageStatus | null;
        } = _.chain(demographics)
          .map((demographic) => [
            demographic,
            dPriorities.find((dP) => dP.demographic === demographic)
              ?.triage_status,
          ])
          .fromPairs()
          .value();

        const archived = _.chain(triageStatusByDemographic)
          .values()
          .filter((status) => status != null)
          .every((status) => status === DomainPriorityTriageStatus.Archived)
          .value();

        return {
          ...triageStatusByDemographic,
          archived: archived,
          domainPriorities: dPriorities,
        };
      })
      .value();
  }, [domainPriorities, demographics]);

  const { sortedData, domainsGroups } = useMemo(() => {
    const domainsGroups = _.isArray(surveyComparison)
      ? _.chain(surveyComparison)
        .reduce<{
          positive: DomainComparison[];
        }>(
          (pV, cV) => {
            return {
              ...pV,
              positive: [...pV.positive, cV],
            };
          },
          {
            positive: [],
          }
        )
        .values()
        .value()
      : [];

    const sortedData: {
      [key in SurveyDemographic]?: {
        domain: Domain;
        meanScore?: number;
      }[][];
    } = _.chain(demographics)
      .reduce((pV, d) => {
        return {
          ...pV,
          [d]: _.chain(domainsGroups)
            .mapValues((items) => {
              return _.chain(items)
                .map((sC) => ({
                  domain: sC.domain,
                  meanScore: sC.respondents?.[d]?.mean_score,
                }))
                .filter((d) => !!d.meanScore)
                .orderBy((d) => d.meanScore ?? "", ["desc"])
                .value();
            })
            .value(),
        };
      }, {})
      .value();

    return {
      domainsGroups: domainsGroups,
      sortedData: sortedData,
    };
  }, [surveyComparison, demographics]);

  const resultData = useMemo(() => {
    const result: {[key in SurveyDemographic]?: ArrayGroups;} = _.mapValues(sortedData, (arrayOfArrays) => {
      if (!arrayOfArrays) return undefined;
      return {
        lastThreeArrays: _.map(arrayOfArrays, (arrays) => _.slice(arrays, -3)),
        otherArrays: _.map(arrayOfArrays, (arrays) => _.slice(arrays, 0, -3)),
      };
    })

    return result;
  }, [sortedData]);

  const [useRemainingElements, setUseRemainingElements] = useState<boolean>(false);

  const loadRemaingGroups = () => {
    setUseRemainingElements((prev) => !prev);
  }

  const domainsGroupsData = useMemo(() => {

    const lastThreeElements = domainsGroups[0].slice(-3);
    const remainingElements = domainsGroups[0].slice(0, -3);
    const firstElement = domainsGroups[0].slice(0, 1);

    const result = useRemainingElements ? [remainingElements, lastThreeElements] : [firstElement, lastThreeElements];

    return result;
  }, [domainsGroups, useRemainingElements]);

  const { loading } = useLoadingWithDependencies({
    loadingDeps: [
      loadingGetSurveyComparison,
      loadingGetDomainPriorities,
      loadingGetDatasetMarks,
    ],
  });


  const filteredDemographics = useMemo(()=>{return demographics.filter(demographic => {
    return sortedData[demographic]?.[0]?.length
  })
},[demographics]);


  const { hiddenDemographics } = useSelector((s) => s.needs.hovered);

  if (loading) {
    return <Spinner intent={"primary"} />;
  }

  if (!domainsGroupsData.length) {
    return (
      <NonIdealState
        icon="zoom-out"
        title={intl.formatMessage({ id: "app.titles.no-data" })}
      />
    );
  }
  
  return (
    <div className="teampage_container strengths_needs Strength_Needs_newPage">
      <div className="needpage-main-content">
      {isPriorityConfirmed && (
        <div className="mb-3">
          {/* <Callout intent="warning">
            {intl.formatMessage({
              id: "app.prioritized-domain-warning-text",
            })}
          </Callout> */}
        </div>
      )}
      <div className="grade">
        {/* <Grid container spacing={2}> */}
        <div className={`grid grid-cols-${filteredDemographics?.length}`}>
        {filteredDemographics?.map((demographic) => (
          // <Grid item xs={6} md={3}>
            <h5 key={demographic} className="grade-heading">{intl.formatMessage({
              id: `app.filters.respondent-type.${demographic}`,
            })}</h5>
          // </Grid>
          ))}
          </div>
        {/* </Grid> */}
      </div>
      {
        domainsGroupsData.map((domainComparisons, comparisonGroupIndex) => {
          let notEnoghGroup = {
            elementary_students: false,
            students: false,
            parents: false,
            school_staff: false
          };      
          return (
            <div key={comparisonGroupIndex} className="domain">
            <h6>{intl.formatMessage({
                id: `app.titles.domains-three.${comparisonGroupIndex}`,
              })}</h6>

                {domainComparisons.length ? (
                  domainComparisons.map((dc, index) => (
                    <div key={index} className="">
                         <div className={`grid grid-cols-${filteredDemographics?.length}`}>
                      {/* <Grid container spacing={2}> */}
                      
                      {filteredDemographics?.map((demographic) => {
                        const lastThreeArrays = resultData[demographic]?.['lastThreeArrays']?.[comparisonGroupIndex-1]?.[index];
                        const otherArrays = resultData[demographic]?.['otherArrays']?.[comparisonGroupIndex]?.[index];

                        let items;
                        if(comparisonGroupIndex === 0) {
                          items = otherArrays;
                        } 
                        if(comparisonGroupIndex === 1) {
                          items = lastThreeArrays;
                        }

                        if (dc.respondents[demographic]?.not_enough_data && sortedData[demographic]?.[0].length && !notEnoghGroup[demographic]) {
                          notEnoghGroup[demographic] = true;

                          return (
                            <div className="flex-1" key={demographic + index}>
                              <Callout
                                title={intl.formatMessage({
                                  id:
                                    "app.non-ideal-state.survey-reports.not-enough-respondents-in-domain",
                                })}
                                intent="warning"
                              />
                            </div>
                          );
                        }

                        if (!items && !sortedData[demographic]?.[0].length && index === 0) {
                          return (
                            <div className="flex-1" key={demographic + index} >
                              <Callout title={intl.formatMessage({ id: "app.non-ideal-state.survey-reports.not-enough-respondents" })} intent="warning" />
                            </div>
                          );
                        }

                        if (!items) {
                          return (
                            <div className="flex-1" key={demographic + index} />
                          );
                        }

                        return (
                          <DomainComparisonCard
                            key={`${items.domain.id}_${demographic}`}
                            hiddenOnDemographicLevel={
                              hiddenDemographics[demographic]
                            }
                            isPriorityConfirmed={isPriorityConfirmed}
                            isDomainPrioritized={triageStatusesDictionary[items.domain.id]}
                            domain={items.domain}
                            surveyDeploymentId={surveyDeploymentId}
                            demographic={demographic}
                            meanScore={items.meanScore}
                            showRedBorder={showRedBorder}
                          />
                        );
                      })}
                      {/* </Grid> */}
                      </div>
                    </div>
                  ))
                ) : (
                  <Callout
                    title={intl.formatMessage({ id: "app.titles.no-data" })}
                    intent="warning"
                  />
                )}
                {domainComparisons.length == 1 ? <Grid item xs={12} md={12}>
                  <a onClick={loadRemaingGroups} className="text-center load-more-btn">Load More</a>
                </Grid> : '' }
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default ExploreStrengthsAndNeedsTab;