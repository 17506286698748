import { ACTIVITY_LINK_CLASSNAME } from "./hooks/useAttachActivities";
import _, { isArray } from "lodash";

export const wrapBracketContent = (
  content: string,
  disableListConversion = false,
  pattern: string = `<a class='${ACTIVITY_LINK_CLASSNAME} font-bold'>$1</a>`
) => {
  const occurrences = new Set<string>();

  const wrappedContentParts = content
    .replaceAll(/{{(.*?)}}/gi, (substring, group1) => {
      occurrences.add(group1);
      return pattern.replace("$1", group1);
    })
    .split("\n")
    .filter((part) => {
      return !!part.trim().length;
    });

  let wrappedContent: string;

  if (disableListConversion) {
    wrappedContent = wrappedContentParts.join("\n");
  } else {
    switch (wrappedContentParts.length) {
      case 0: {
        wrappedContent = "";
        break;
      }
      case 1: {
        wrappedContent = wrappedContentParts[0];
        break;
      }
      default: {
        wrappedContent = wrappedContentParts
          .map((line) => line).join("");
      }
    }
  }

  return {
    wrappedContent,
    occurrences: Array.from(occurrences),
  };
};

export const wrapObjectFields = (obj: Object = {}) => {
  return _.chain(obj)
    .entries()
    .reduce<any>(
      (pV: any, [key, value]: any) => {
        if (value == null) {
          return pV;
        }

        const { wrappedContent, occurrences } = wrapBracketContent(
          value,
          false,
          `<b>$1</b>`
        );
        return {
          ...pV,
          [key]: wrappedContent,
          occurrences: key === "agenda" ? occurrences : pV.occurrences,
        };
      },
      {
        occurrences: [],
      }
    )
    .value();
};

export const generateQueryString = (request: Object) => {
  return _.chain(request)
    .toPairs()
    .filter(([key, value]) => value !== undefined && value !== null)
    .flatMap(([key, value]) => {
      if (isArray(value)) {
        return value.map((filter) => `${key}[]=${filter}`);
      }
      return `${key}=${value}`;
    })
    .join("&")
    .value();
};

export const makeRequestPath = (
  url: string,
  request: Object | Object[],
  delimiter: string = "?"
) => {
  if (request) {
    const queryStr = isArray(request)
      ? request.map((r) => generateQueryString(r)).join("&")
      : generateQueryString(request);

    if (queryStr.length) {
      return url + delimiter + queryStr;
    }
  }
  return url;
};
