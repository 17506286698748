import Pusher from "pusher-js";
import {PUSHER_KEY} from "../../../../api/config";

const PriorityEvents = {
    priorityConfirm: 'prioritize_confirm_for_goal',
    priorityConfirmChannel: 'prioritize-not-prioritize',
    pusher : new Pusher(PUSHER_KEY, {
        cluster: "us2",
        channelAuthorization: {
          endpoint: "/pusher/auth",
          transport: "ajax",
          params: {},
          headers: {},
        },
        userAuthentication: {
          endpoint: "/pusher/auth",
          transport: "ajax",
          headers: {
            "X-CSRF-Token": "some_csrf_token",
          },
        },
    })
}

export default PriorityEvents;