import { Button, Icon, Spinner } from "@blueprintjs/core";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getSurveyDeploymentsBySchool } from "../../../../../store/surveys-deployment/actions";
import _, { isEmpty } from "lodash";
import StrategyIndicatorGraph from "./table-view/StrategyIndicatorGraph";
import SurveyIndicatorGraph from "./table-view/SurveyIndicatorGraph";
import "./IndicatorGraph.scss";
import { clearRespondentGraphDomainsItemsArray, getRespondentGraphDomainData, getRespondentGraphItemData } from "../../../../../store/survey-reports/actions";

type OwnProps = {
  selectedMultiRespondentGroup: any;
  selectedGoal: any;
  selectedDateRange: any;
  selectedDomain: any;
  selectedAlignedStrategy: any;
  selectedSurveyItem: any;
  saveFilter: any;
  alignStrategies: any;
};

type Props = OwnProps;

const STRATEGIES_THRESHOLD: number=30;

const IndicatorGraph: React.FC<Props> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  ////STATES
  const [dataSetLegend, setDataSetLegend] = useState([]);
  const [isChartSurvey, setIsChartSurvey] = useState<boolean>(true);
  const [isChartStratery, setIsChartStrategy] = useState<boolean>(true);
  const [SurveyIdIndex, setSurveyIdIndex] = useState<number>(0);
  const [ItemsIndex, setItemsIndex] = useState<number>(0);
  const [idsArray, setIdsArray] = useState<any>([]);

  const [refresh,setRefresh]=useState({surveyChart:true,strategyChart:true});

  const [zoomSurveyChartLevel,setZoomSurveyChartLevel]=useState<any>(0);
  const [zoomStrategyChartLevel,setZoomStrategyChartLevel]=useState<any>(0);


  const {selectedMultiRespondentGroup,
    selectedGoal,
    selectedDateRange,
    selectedDomain,
    selectedAlignedStrategy,
    selectedSurveyItem,
    saveFilter,
    alignStrategies,} = props;
 
  ///// Selectors///////
   
  const { goals } = useSelector((s) => s.goals);
  const IndicatorGraphDomainsData=useSelector((s) => s.surveyReports.surveyRespondentDomainsData)
  const IndicatorGraphItemsData=(useSelector((s) => s.surveyReports.surveyRespondentItemsData))
  const IndicatorGraphDomainsDataLoading=useSelector((s) => s.surveyReports.loading.getRespondentGraphDomainData)
  const IndicatorGraphItemsDataLoading=useSelector((s) => s.surveyReports.loading.getRespondentGraphItemData)
  const surveyDeployments = useSelector((s) => s.surveysDeployment.surveysDeploymentBySchool);
  const [ImplementationDataSetLegend, setImplementationDataSetLegend] = useState([]);
  const [HexColorArray,setHexColorArray]=useState<any>([]);

  ///////////
  const selectedDomainIdsFiltered = selectedGoal.selected_domains.map(
    (sd: any) => sd.id
  ); // selected domains ids

  const filteredSurveyDeployments= surveyDeployments.filter((surveyDeployment:any)=>surveyDeployment.is_deleted===false && surveyDeployment.state==="ready");
  const surveyDeploymentIds = filteredSurveyDeployments.map((s: any) => s.id); // survey deployment ids

  
  useEffect(() => {
    if (SurveyIdIndex === 0) {
      dispatch(clearRespondentGraphDomainsItemsArray([]));
    }
    if (
      SurveyIdIndex < surveyDeploymentIds.length &&
      !IndicatorGraphDomainsDataLoading
    ) {
      dispatch(
        getRespondentGraphDomainData.request({
          survey_deployment_id: [surveyDeploymentIds[SurveyIdIndex]],
          school_id: [selectedGoal.plans.school.id],
          // hide_sensitive_reporting_data:localFlagToHideSensitiveData
        })
      );
      setSurveyIdIndex(SurveyIdIndex + 1);
    }
  }, [surveyDeployments, IndicatorGraphDomainsDataLoading]);


useEffect(() => {
 if(idsArray.length === 0){
   surveyDeploymentIds.forEach((value: any, index: number) => {
    selectedDomainIdsFiltered.forEach((domain_id: any, ind: number) => {
      idsArray.push({ sid: value, domain_id: domain_id });
    });
  });}
}, [surveyDeployments]);

useEffect(() => {
  if (ItemsIndex < idsArray.length && !IndicatorGraphItemsDataLoading) {
    dispatch(
      getRespondentGraphItemData.request({
        survey_deployment_id: [idsArray[ItemsIndex]?.sid],
        school_id: [selectedGoal.plans.school.id],
        domain_id: [idsArray[ItemsIndex]?.domain_id],
      })
    );
    setItemsIndex(ItemsIndex + 1);
  }
}, [surveyDeployments, IndicatorGraphItemsDataLoading]);




useEffect(()=>{
  function getRandomHexColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    const minBrightness = 100; 
    
    function getBrightness(hex:any) {
      const r = parseInt(hex.substring(1, 3), 16);
      const g = parseInt(hex.substring(3, 5), 16);
      const b = parseInt(hex.substring(5, 7), 16);
      return (r * 299 + g * 587 + b * 114) / 1000;
    }

    do {
      color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
    } while (getBrightness(color) > minBrightness);
  
    return color;
  }

    for (let i = 0; i<=STRATEGIES_THRESHOLD;i++){
      HexColorArray.push(getRandomHexColor())
    }
  },[surveyDeployments])
/////////


////useMemo
  const data: any = useMemo(() => {
    const DataSet: any = []; //graph dataset Array
    const targetScore:any=[];

  if((selectedDomain||selectedSurveyItem) && selectedMultiRespondentGroup.length===1) { 
    let target_date;
    if(selectedDomain){
      selectedGoal.targets.map((target: any) =>
      target?.domain_priority?.demographic===selectedMultiRespondentGroup[0] && target?.domain_priority?.domain?.name===selectedDomain
    ?selectedDateRange.map((date:any)=> 
    targetScore.push(target.target_value)
    ,target_date= moment(target.target_date).format("MM/DD/YY")
    )
    :""
    )
  }

    if(selectedSurveyItem){
      selectedGoal.targets.map((target: any) =>
      target?.item_priority?.demographic===selectedMultiRespondentGroup[0] && target?.item_priority?.item?.text.includes(selectedSurveyItem)
    ?selectedDateRange.map((date:any)=> 
    targetScore.push(target.target_value)
    ,target_date= moment(target.target_date).format("MM/DD/YY")
    )
    :""
    )}

    DataSet.push({
      label:target_date ,
      pointBorderWidth: 1,
      pointRadius: 0.2,
      pointHoverRadius: 1,
      data:targetScore,
      fill: false,
      tension: 0.1,
      hoverBorderColor: "orange",
      backgroundColor: "orange",
      pointStyle: "circle",
      borderDash: [3, 3],
      borderColor: "orange",
    })
  }


    if (selectedDomain) {
      const parents: any = [];
      const students: any = [];
      const staff: any = [];
      const elementary_students: any = [];
      selectedMultiRespondentGroup.map((rd: any, index: number, arr: any) => {
        //find domain and color_hex from selected Goal
      
        const bgColor = IndicatorGraphDomainsData.find((fsc:any)=>fsc?.domain?.name ===  selectedDomain)?.domain?.color_hex

        //     // data points for domain graph
        //find mean score based on selected dates from seleted Goal Array
        const selectedIndicatorDataPoints = (rd: any, arr: any) => {
          selectedDateRange.map((sdr: any) =>
            IndicatorGraphDomainsData.map((rg: any) =>
            rg?.domain?.name ===  selectedDomain && rg?.respondents[`${rd}`] && 
            sdr === moment(rg?.survey_deployment[0]?.end_date).format("MM/DD/YY")? 
                arr.splice(
                      selectedDateRange.findIndex(
                        (item: any) => item ===moment(rg?.survey_deployment[0]?.end_date).format("MM/DD/YY")
                      ),
                      0,
                      (rg?.respondents[`${rd}`].mean_score)?.toFixed(2)
                    )
                  : arr.push(null)
              )
          );
        };

        if (rd === "parents") {
          selectedIndicatorDataPoints(rd, parents); //data points array

          //     // pushing Domain Data in the Graph DataSet Array
          DataSet.push({
            label:rd ,
            pointBorderWidth: 1,
            pointRadius: 5,
            pointHoverRadius: 10,
            data: parents,
            fill: false,
            tension: 0.1,
            hoverBorderColor: bgColor,
            backgroundColor: bgColor,
            pointStyle: "rectRot",
            borderDash: [0, 0],
            borderColor: bgColor,
          });
        } else if (rd === "students") {
          selectedIndicatorDataPoints(rd, students); //data points array

          //     // pushing Domain Data in the Graph DataSet Array
          DataSet.push({
            label: rd ,
            pointBorderWidth: 1,
            pointRadius: 5,
            pointHoverRadius: 10,
            data: students,
            fill: false,
            tension: 0.1,
            hoverBorderColor: bgColor,
            backgroundColor: bgColor,
            pointStyle: "rect",
            borderDash: [0, 0],
            borderColor: bgColor,
          });
        } else if (rd === "school_staff") {
          selectedIndicatorDataPoints(rd, staff); //data points array

          //     // pushing Domain Data in the Graph DataSet Array
          DataSet.push({
            label: rd ,
            pointBorderWidth: 1,
            pointRadius: 5,
            pointHoverRadius: 10,
            data: staff,
            fill: false,
            tension: 0.1,
            hoverBorderColor: bgColor,
            backgroundColor: bgColor,
            pointStyle: "triangle",
            borderDash: [0, 0],
            borderColor: bgColor,
          });
        } else if (
          rd === "elementary_students"
        ) {
          selectedIndicatorDataPoints(rd, elementary_students); //data points array

          //     // pushing Domain Data in the Graph DataSet Array
          DataSet.push({
            label:  rd ,
            pointBorderWidth: 1,
            pointRadius: 5,
            pointHoverRadius: 10,
            data: elementary_students,
            fill: false,
            tension: 0.1,
            hoverBorderColor: bgColor,
            backgroundColor: bgColor,
            pointStyle: "circle",
            borderDash: [0, 0],
            borderColor: bgColor,
          });
        }
      });
    }

    if (selectedSurveyItem) {
      const parents: any = [];
      const students: any = [];
      const staff: any = [];
      const elementary_students: any = [];
      selectedMultiRespondentGroup.map((rd: any, index: number, arr: any) => {
        //find domain and color_hex from selected Goal
      
        const bgColor =  IndicatorGraphDomainsData.find((fsc:any)=>
        fsc?.domain?.name === 
        (selectedGoal.targets.find((target:any)=>target?.item_priority?.item?.text===selectedSurveyItem)?.item_priority?.domain?.name) 
        )?.domain?.color_hex

        //     // data points for domain graph
        //find mean score based on selected dates from seleted Goal Array
        const selectedIndicatorDataPoints = (rd: any, arr: any) => {
          selectedDateRange.map((sdr: any) =>
            IndicatorGraphItemsData.map((rg: any) =>
            rg?.responses?.forEach((response:any) => {
              if (
                response.respondents[`${rd}`] &&
                response.respondents[`${rd}`]?.question_text?.includes(selectedSurveyItem)
                &&sdr === moment(response?.survey_deployment[0]?.end_date).format("MM/DD/YY")
              ) {
                arr.splice(
                  selectedDateRange.findIndex(
                    (item: any) =>
                      item ===
                      moment(response?.survey_deployment[0]?.end_date).format(
                        "MM/DD/YY"
                      )
                  ),
                  0,
                  (response?.respondents?.[`${rd}`]?.mean_score)?.toFixed(2)
                )
              }
              else{arr.push(null)}
            })
              
            )
          );
        };

        if (rd === "parents") {
          selectedIndicatorDataPoints(rd, parents); //data points array

          //     // pushing Domain Data in the Graph DataSet Array
          DataSet.push({
            label:rd ,
            pointBorderWidth: 1,
            pointRadius: 5,
            pointHoverRadius: 10,
            data: parents,
            fill: false,
            tension: 0.1,
            hoverBorderColor: bgColor,
            backgroundColor: bgColor,
            pointStyle: "rectRot",
            borderDash: [5,5],
            borderColor: bgColor,
          });
        } else if (rd === "students") {
          selectedIndicatorDataPoints(rd, students); //data points array

          //     // pushing Domain Data in the Graph DataSet Array
          DataSet.push({
            label: rd ,
            pointBorderWidth: 1,
            pointRadius: 5,
            pointHoverRadius: 10,
            data: students,
            fill: false,
            tension: 0.1,
            hoverBorderColor: bgColor,
            backgroundColor: bgColor,
            pointStyle: "rect",
            borderDash: [5,5],
            borderColor: bgColor,
          });
        } else if (rd === "school_staff") {
          selectedIndicatorDataPoints(rd, staff); //data points array

          //     // pushing Domain Data in the Graph DataSet Array
          DataSet.push({
            label: rd ,
            pointBorderWidth: 1,
            pointRadius: 5,
            pointHoverRadius: 10,
            data: staff,
            fill: false,
            tension: 0.1,
            hoverBorderColor: bgColor,
            backgroundColor: bgColor,
            pointStyle: "triangle",
            borderDash: [5,5],
            borderColor: bgColor,
          });
        } else if (
          rd === "elementary_students"
        ) {
          selectedIndicatorDataPoints(rd, elementary_students); //data points array

          //     // pushing Domain Data in the Graph DataSet Array
          DataSet.push({
            label:  rd ,
            pointBorderWidth: 1,
            pointRadius: 5,
            pointHoverRadius: 10,
            data: elementary_students,
            fill: false,
            tension: 0.1,
            hoverBorderColor: bgColor,
            backgroundColor: bgColor,
            pointStyle: "circle",
            borderDash: [5,5],
            borderColor: bgColor,
          });
        }
      });
    }
    setDataSetLegend(DataSet);
    
    return {
      labels: selectedDateRange, // dates
      datasets: DataSet,
    };
  }, [saveFilter,IndicatorGraphDomainsDataLoading,IndicatorGraphItemsDataLoading]);



          const AdminLabelPoints: any = {};
          selectedDateRange.map((sdr:any)=>
              IndicatorGraphDomainsData.map((fsc: any) =>
                sdr === moment(fsc?.survey_deployment[0]?.end_date).format("MM/DD/YY")?
                  AdminLabelPoints[moment(fsc?.survey_deployment[0]?.end_date).format("MM/DD/YY")]=fsc?.survey_deployment[0]?.deployment_name                          
                  : ""
            ))                

  const options: any = useMemo(
    () => ({
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label: function (tooltipItem:any, data:any) {
     let label = "  "+data.datasets[tooltipItem.datasetIndex].label;
     label =["parents","elementary_students","students","school_staff"].includes(data.datasets[tooltipItem.datasetIndex].label) ? "   "+intl.formatMessage({ id: `app.filters.respondent-type.${data.datasets[tooltipItem.datasetIndex].label}`}) : label

     return [data.datasets[tooltipItem.datasetIndex].borderDash.includes(3)? `  ${intl.formatMessage({id:"app.goals.target-indicator.target-score"})}: `+tooltipItem.yLabel.toFixed(2) :label];
   },
   title: function (tooltipItem:any,data:any) {
     // Return an array of title linesn
     const showAdminLabel= AdminLabelPoints[tooltipItem[0].label]
    
     if(showAdminLabel){
     return ["",AdminLabelPoints[tooltipItem[0].label]?.slice(0,10) +AdminLabelPoints[tooltipItem[0].label]?.slice(10,16)+" "+AdminLabelPoints[tooltipItem[0].label]?.slice(26),""];
     }

   },
   afterLabel: function (tooltipItem:any,data:any) {
     // Additional labels if needed
     return ["",data.datasets[tooltipItem.datasetIndex].borderDash.includes(0)
     ? `    ${intl.formatMessage({id:"app.titles.domain-mean"})}: ${
      (tooltipItem.yLabel)?.toString()?.split(".")[1]?.length===1 ? 
      tooltipItem.yLabel+"0"
      :(tooltipItem.yLabel)?.toString()?.split(".")[1]?.length===undefined ? 
      tooltipItem.yLabel+".00"
      
      :tooltipItem.yLabel
    }`
     : data.datasets[tooltipItem.datasetIndex].borderDash.includes(3)? `${intl.formatMessage({id:"app.goals.target-indicator.achieved-by"})} `+data.datasets[tooltipItem.datasetIndex].label :`   ${intl.formatMessage({id:"app.survey-report.dialogs.item"})} ${intl.formatMessage({id:"app.survey-report.dialogs.mean"})}: ${  
      (tooltipItem.yLabel)?.toString()?.split(".")[1]?.length===1 ? 
      tooltipItem.yLabel+"0"
      :(tooltipItem.yLabel)?.toString()?.split(".")[1]?.length===undefined ? 
      tooltipItem.yLabel+".00"
      
      :tooltipItem.yLabel
    }`,""];
   
 },
       },
      },
      legend: { display: false, position: "right" },
      spanGaps: true,
      scales: {
        xAxes: [
          {
            ticks: {
              fontColor: "black",
              maxRotation: 70,
              minRotation: 70,
              maxTicksLimit:zoomSurveyChartLevel===0?selectedDateRange.length/40:  selectedDateRange.length/zoomSurveyChartLevel*40,
              callback:(value:any,index:number)=>index%4===0? value:"-"
            },
            gridLines: {
              zeroLineColor: "black",
              color: "white",
            },
          },
         
        ],

        yAxes: [
          {
            id: "score",
            type: "linear",
            ticks: {
              min: 0,
              max: 4,
              stepSize: 1,
              beginAtZero: true,
              fontColor: "black",
              callback: (value:any) => (value !== 0 ? value : '')

            },
            gridLines: {
              zeroLineColor: "black",
              drawTicks: { font: { color: "white" } },
            },
          },
        ],
      },
    }),
    [saveFilter,IndicatorGraphDomainsDataLoading,IndicatorGraphItemsDataLoading]
  );

  const data2: any = useMemo(() => {
    
    const DataSet: any = [];
    const StrategyDataPoints = (sas: any) => {
      const alignedStrategyImplementationData: any = [];

      selectedDateRange.map((sdr: any) =>    
      alignStrategies?.map((item:any) =>
        item.strategy.name === sas
          && item.implementations.map((impl: any) =>
                sdr === moment(impl.date).format("MM/DD/YY")
                  ? alignedStrategyImplementationData.splice(
                      selectedDateRange.findIndex(
                        (dateRange: any) =>
                          dateRange ===
                          moment(impl.date).format("MM/DD/YY")
                      ),
                      0,
                      Math.round((impl.score || 0) * 100)
                    )
                  : alignedStrategyImplementationData.push(null)
              )
            )
      );

      return alignedStrategyImplementationData;
    };

    if (selectedAlignedStrategy) {
      selectedAlignedStrategy.map((sas: string,index:number) =>
      DataSet.push({
        label: sas,
        data: StrategyDataPoints(sas),
        fill: false,
        tension: 0.1,
        pointBorderWidth: 1,
        pointRadius: 5,
        pointHoverRadius: 10,
        backgroundColor: HexColorArray[index],
        borderColor: HexColorArray[index],
      })
    );
    }

    setImplementationDataSetLegend(DataSet)
    return {
      labels: selectedDateRange,
      datasets: DataSet,
    };
  }, [saveFilter]);
  const options2: any = useMemo(
    () => ({
      maintainAspectRatio: false,
      responsive: true,
      tooltips: {
        callbacks: {
          label: function (tooltipItem:any, data:any) {
     let label = "  "+data.datasets[tooltipItem.datasetIndex].label || '';
     
     return [label];
   },
   title: function (tooltipItem:any,data:any) {
     // Return an array of title lines
     return [""];
   },
   afterLabel: function (tooltipItem:any,data:any) {
     // Additional labels if needed
     return ["  "+tooltipItem.label,"  Score: " + tooltipItem.yLabel +"%"];
   
 },
       },
      },

      legend: {
        display: false,
        position: "right",
        labels: {
          fontColor: "black", // Change the color of the legend labels
          usePointStyle: true, // Use custom point style
          pointRadius: 5,
          boxWidth: 7,
          pointStyle: "circle",
        },
      },
      spanGaps: true,
      scales: {
        xAxes: [
          {
            ticks: {
              fontColor: "black",maxRotation: 70,minRotation: 70,
              maxTicksLimit:zoomStrategyChartLevel===0?selectedDateRange.length/40:  selectedDateRange.length/zoomStrategyChartLevel*40,

              callback:(value:any,index:number)=>index%4===0? value:"-"
            },
            gridLines: {
              zeroLineColor: "black",
              color: "white",
            },
          },
        ],
        yAxes: [
          {
            id: "score",
            type: "linear",
            ticks: {
              min: 0,
              max: 100,
              stepSize: 20,
              beginAtZero: true,
              fontColor: "black",
              callback: (value:any) => (value !== 0 ? value : '')
            },
            gridLines: {
              zeroLineColor: "black",
            },
          },
        ],
      },
    }),
    []
  );

const isLoading=useMemo(()=>{
 if(ItemsIndex < idsArray.length && SurveyIdIndex < surveyDeploymentIds.length){

return  true

}else{
  return false;
}
},[ItemsIndex,SurveyIdIndex])





const DecreaseSurveyChartZoom=()=>{
  setZoomSurveyChartLevel(zoomSurveyChartLevel>0? (zoomSurveyChartLevel-1):0)
}
const IncreaseSurveyChartZoom=()=>{
  
  setZoomSurveyChartLevel(zoomSurveyChartLevel<3? +(zoomSurveyChartLevel+1):zoomSurveyChartLevel)
}

useEffect(()=>{

setTimeout(() => {
  setRefresh({...refresh,surveyChart:false})
}, 500);

setTimeout(() => {
  setRefresh({...refresh,surveyChart:true})

}, 600);

},[zoomSurveyChartLevel])






const DecreaseStrategyChartZoom=()=>{
  setZoomStrategyChartLevel(zoomStrategyChartLevel>0? (zoomStrategyChartLevel-1):0)
}
const IncreaseStrategyChartZoom=()=>{
  
  setZoomStrategyChartLevel(zoomStrategyChartLevel<3? +(zoomStrategyChartLevel+1):zoomStrategyChartLevel)
}

useEffect(()=>{

setTimeout(() => {
  setRefresh({...refresh,strategyChart:false})
}, 500);

setTimeout(() => {
  setRefresh({...refresh,strategyChart:true})

}, 600);

},[zoomStrategyChartLevel])





////////////
  return (
    <div>
      <div className="monitoring_header">
        <div className="m-auto">
        <h3 className="d-flex justify-center mb-1">{intl.formatMessage({id:"app.titles.nj-sci-survey-data"})}</h3>

          {selectedDomain ? (
            <p className="d-flex justify-center mb-5">
              <strong>{intl.formatMessage({ id: "app.titles.domain" })}: &nbsp;</strong>
              <span>{selectedDomain}</span>
            </p>
          ) : (
            <p className="d-flex justify-center mb-5">
              <b>{intl.formatMessage({ id: "app.titles.survey-item" })}:&nbsp;&nbsp;</b>{" "}
              {selectedSurveyItem} (
              {IndicatorGraphDomainsData.find((fsc:any)=>
              
                fsc?.domain?.name === (selectedGoal.targets.find((target:any)=>target?.item_priority?.item?.lead_in?target?.item_priority?.item?.lead_in+target?.item_priority?.item?.text:target?.item_priority?.item?.text===selectedSurveyItem)?.item_priority?.domain?.name) 
                )?.domain?.name
                })
            </p>
          )}
        </div>
        <div className="monitoring_header_links">
          <ul>
            {
              !isChartSurvey ?
                <li>
                  <button className="linksBtn" onClick={() => setIsChartSurvey(true)}><Icon iconSize={16} icon="timeline-bar-chart" /> &nbsp;{intl.formatMessage({id:"app.titles.chart-view"})}</button>
                </li>
                :
                <li>
                  <button className="linksBtn" onClick={() => setIsChartSurvey(false)}>
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                        <path d="M13.0452 13.2274L10.8351 13.2272V10.2757L13.777 10.2759V12.4956C13.777 12.9334 13.4829 13.2274 13.0452 13.2274ZM2.2757 12.4956V10.2757L5.15838 10.2759V13.2274L3.00749 13.2272C2.56971 13.2274 2.2757 12.9334 2.2757 12.4954M10.8351 6.01829H13.7769V8.85664H10.8351V6.01829ZM5.15838 8.85664L2.2757 8.85649V6.01829L5.15838 6.01813V8.85664ZM9.41599 13.2274L6.57764 13.2272V10.2757L9.41599 10.2759V13.2274ZM6.57764 6.01829H9.41599V8.85664H6.57764V6.01829ZM3.00749 1.72589H13.0452C13.4829 1.72589 13.777 2.02006 13.777 2.45769V4.59904H2.2757V2.45784C2.2757 2.02006 2.56971 1.72589 3.00749 1.72589ZM13.0452 0.306641H3.00749C1.7885 0.306641 0.856445 1.23885 0.856445 2.45769V12.4956C0.856445 13.7144 1.7885 14.6466 3.00749 14.6466L13.0452 14.6465C14.2641 14.6466 15.1961 13.7144 15.1961 12.4954V2.45769C15.1961 1.23885 14.2641 0.306641 13.0452 0.306641Z" fill="#195A94" />
                      </svg>
                    </span> &nbsp; {intl.formatMessage({id:"app.titles.table-view"})}
                  </button>
                </li>
            }

            <li>
              {" "}
              <button className="linksBtn">
                <Icon iconSize={15} icon="import" />&nbsp; {intl.formatMessage({id:"app.title.graph.download"})}
              </button>
            </li>
          </ul>
        </div>
      </div>
      {
        isChartSurvey ? (<>
            <div className="flex justify-center">
            <Button intent="none" onClick={()=>DecreaseSurveyChartZoom()} >
              <Icon iconSize={20} icon="zoom-out" />
              </Button>
              <p className="text-lg mt-1 ml-2 mr-2">
              {zoomSurveyChartLevel<=3 && zoomSurveyChartLevel>0?zoomSurveyChartLevel+"x":zoomSurveyChartLevel}
                </p>
                <Button intent="none" onClick={()=>IncreaseSurveyChartZoom()}>

                <Icon iconSize={20} icon="zoom-in" />
                </Button>
              </div>
          {refresh.surveyChart?<div className="m-auto pb-20 GraphData" >
           {isLoading?<Spinner intent="primary"/> : <div className="graphScroll">

            <div className="graphDiv mb-5" style={{ height: "300px",width:`${selectedDateRange.length*(zoomSurveyChartLevel/2)}rem`,minWidth:"800px"}}>
              <Line data={data} options={options} />
            </div>
            </div>
        }

            <div className="indicatorDiv">
           
              <ul>
                {(!isLoading)&&dataSetLegend.map((ds: any, index: number) => !isEmpty(ds.data) && (
                  <>
                  <li key={index}>
                    {ds.label === "school_staff" ? (
                       <>
                      {!ds.borderDash.includes(3) ?   <>              
                        <span className="indicatorTriangle" style={{ borderBottom: `10px solid ${ds.backgroundColor}`, }} >
                          <span className="graphTrack" style={{ backgroundColor: ds.backgroundColor }} ></span>{" "}
                        </span> {" "}
                        <span>
                        {ds.label && intl.formatMessage({ id: `app.filters.respondent-type.${ds.label}`, })}
                        </span></> : ''}
                        <br/>
                        {ds.borderDash.includes(3) ?
                         <div className="indicatorCircles">
                         <span className="circleTrack">
                            <span className="circlegrp">
                              <div></div>
                              <div></div>
                              <div></div>
                            </span>
                            </span>
                        <span>{`${intl.formatMessage({id:"app.goals.target-indicator.target-score"})}: ${Number(ds.data.find((item:any)=>item != null))?.toFixed(2)}`}</span> 
                        </div>
                        : ''}
                      </>
                    ) : ds.label === "elementary_students" ? (
                      <>
                        {!ds.borderDash.includes(3) ? <>
                        <span className="indicatorCircle" style={{ background: ds.backgroundColor }} >
                          <span className="graphTrack" style={{ backgroundColor: ds.backgroundColor }} ></span>{" "}
                        </span> {""}
                        <span>
                            {ds.label && intl.formatMessage({ id: `app.filters.respondent-type.${ds.label}`, })}
                          </span></>
                          :'' } <br/>
                          {ds.borderDash.includes(3) ? 
                          <div className="indicatorCircles">
                         <span className="circleTrack">
                            <span className="circlegrp">
                              <div></div>
                              <div></div>
                              <div></div>
                            </span>
                            </span>
                          <span>{`${intl.formatMessage({id:"app.goals.target-indicator.target-score"})}: ${Number(ds.data.find((item:any)=>item != null))?.toFixed(2)}`}</span>
                        </div> :'' }
                         
                        </>
                      ) : ds.label === "students" ? (
                        <>
                         {!ds.borderDash.includes(3) ? <>
                          <span className="indicatorSquare" style={{ background: ds.backgroundColor }} >
                            <span className="graphTrack" style={{ backgroundColor: ds.backgroundColor }} ></span>{" "}
                          </span>{" "}
                          <span>
                              {ds.label && intl.formatMessage({ id: `app.filters.respondent-type.${ds.label}`, })}
                            </span>
                            </>
                          :'' } <br/>
                           {ds.borderDash.includes(3) ? 
                            <div className="indicatorCircles">
                         <span className="circleTrack">
                            <span className="circlegrp">
                              <div></div>
                              <div></div>
                              <div></div>
                            </span>
                            </span>
                            <span>{`${intl.formatMessage({id:"app.goals.target-indicator.target-score"})}: ${Number(ds.data.find((item:any)=>item != null))?.toFixed(2)}`}</span>
                          </div>
                          :''}
                          </>
                        ) : (      
                          <>
                            {!ds.borderDash.includes(3) ? <>
                            <span className="indicatorDiamond" style={{ background: ds.backgroundColor }}>
                              <span className="graphTrack" style={{ backgroundColor: ds.backgroundColor }}></span>{" "}
                            </span>{" "}
                            <span>{ds.label && intl.formatMessage({ id: `app.filters.respondent-type.${ds.label}`, })}</span></>
                            : ''}
                             {ds.borderDash.includes(3) ?
                            <div className="indicatorCircles">
                            <span className="circleTrack">
                            <span className="circlegrp">
                              <div></div>
                              <div></div>
                              <div></div>
                            </span>
                            </span>
                            <span>
                            {`${intl.formatMessage({id:"app.goals.target-indicator.target-score"})}: ${Number(ds.data.find((item:any)=>item != null))?.toFixed(2)}`}
                            </span>
                            </div> 
                            : ''}
                      </>
                    )}
                  </li>
                  </>
                ))}
              </ul>
            </div>
          </div>
          :<Spinner/>
          }
          </>
        )
          : (
            isLoading?<Spinner intent="primary"/> : <SurveyIndicatorGraph
             selectedMultiRespondentGroup={selectedMultiRespondentGroup} 
             selectedDomain={selectedDomain} 
             selectedSurveyItem={selectedSurveyItem}
             AdminLabelPoints={AdminLabelPoints}
             IndicatorGraphDomainsData={IndicatorGraphDomainsData}
             IndicatorGraphItemsData={IndicatorGraphItemsData}
             selectedGoal={selectedGoal}
             selectedDateRange={selectedDateRange}
             />
          )
      }

      <div className="monitoring_header mb-5">
        <div className="m-auto text-center ">
          <h3 className="d-flex justify-center">
          {intl.formatMessage({id:"app.titles.strategy-implementation-data"})}

          </h3>
          {selectedAlignedStrategy.length > 0 ? (
            ""
          ) : (
            <h3 className="d-flex justify-center">{intl.formatMessage({id:"app.titles.no-aligned-strategy"})}</h3>
          )}
        </div>

        <div className="monitoring_header_links">
          <ul>
            {
              !isChartStratery ?
                <li>
                  <button className="linksBtn" onClick={() => setIsChartStrategy(true)}>
                    <Icon iconSize={16} icon="timeline-bar-chart" /> &nbsp;{intl.formatMessage({id:"app.titles.chart-view"})}</button>
                </li>
                :
                <li>
                  <button className="linksBtn" onClick={() => setIsChartStrategy(false)}>
                    <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                      <path d="M13.0452 13.2274L10.8351 13.2272V10.2757L13.777 10.2759V12.4956C13.777 12.9334 13.4829 13.2274 13.0452 13.2274ZM2.2757 12.4956V10.2757L5.15838 10.2759V13.2274L3.00749 13.2272C2.56971 13.2274 2.2757 12.9334 2.2757 12.4954M10.8351 6.01829H13.7769V8.85664H10.8351V6.01829ZM5.15838 8.85664L2.2757 8.85649V6.01829L5.15838 6.01813V8.85664ZM9.41599 13.2274L6.57764 13.2272V10.2757L9.41599 10.2759V13.2274ZM6.57764 6.01829H9.41599V8.85664H6.57764V6.01829ZM3.00749 1.72589H13.0452C13.4829 1.72589 13.777 2.02006 13.777 2.45769V4.59904H2.2757V2.45784C2.2757 2.02006 2.56971 1.72589 3.00749 1.72589ZM13.0452 0.306641H3.00749C1.7885 0.306641 0.856445 1.23885 0.856445 2.45769V12.4956C0.856445 13.7144 1.7885 14.6466 3.00749 14.6466L13.0452 14.6465C14.2641 14.6466 15.1961 13.7144 15.1961 12.4954V2.45769C15.1961 1.23885 14.2641 0.306641 13.0452 0.306641Z" fill="#195A94" />
                    </svg></span> &nbsp; {intl.formatMessage({id:"app.titles.table-view"})}
                  </button>
                </li>
            }
          </ul>
        </div>
      </div>
      {
        isChartStratery ? (<>
        <div className="flex justify-center">
            <Button intent="none" onClick={()=>DecreaseStrategyChartZoom()} >
              <Icon iconSize={20} icon="zoom-out" />
              </Button>
              <p className="text-lg mt-1 ml-2 mr-2">
              {zoomStrategyChartLevel<=3 && zoomStrategyChartLevel>0?zoomStrategyChartLevel+"x":zoomStrategyChartLevel}
                </p>
                <Button intent="none" onClick={()=>IncreaseStrategyChartZoom()}>

                <Icon iconSize={20} icon="zoom-in" />
                </Button>
              </div>

          {refresh.strategyChart?<div className="m-auto pb-20 GraphData">
            <div className="graphScroll">
            <div className="graphDiv mb-5" style={{ height: "300px",width:`${selectedDateRange.length*(zoomStrategyChartLevel/2)}rem`,minWidth:"800px"}}>
              {/* {selectedAlignedStrategy.length > 0 ? "" : (<p className="d-flex justify-center mb-5">{intl.formatMessage({ id: "app.titles.no-aligned-strategy" })}</p>)} */}
              <Line data={data2} options={options2} />
            </div>
            </div>
            <div className="indicatorDiv">
            <ul>            
              {(ImplementationDataSetLegend.filter((item:any)=>!isEmpty(item.data))).map((ds: any, index: number) => (
                <li key={index}>
                  <span
                    className="indicatorCircle"
                    style={{ backgroundColor: ds.backgroundColor }}
                  >
                  </span>
                  <span>{ds.label}</span>
                </li>
              ))}
            </ul>
          </div>
          </div>:<Spinner />}
          
          </>
        )
          : (
            <StrategyIndicatorGraph selectedAlignedStrategy={selectedAlignedStrategy} selectedDateRange={selectedDateRange} alignStrategies={alignStrategies} />
          )
      }
    </div>
  );
};

export default IndicatorGraph;
