import { useEffect, useRef } from 'react';

const useDebounce = (callback:(...args: any[]) => void, delay:number) => {
  const timer = useRef<NodeJS.Timeout | null>(null);

  const debouncedFunction = (...args:any) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  return debouncedFunction;
};



export default useDebounce;