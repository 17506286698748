import React, { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from '@mui/material/Dialog';
import PrioritizeAndDoNotPrioritizeDomainDialogContent from "./PrioritizeAndDoNotPrioritizeDomainDialogContent"
import './PrioritizeAndDoNotPrioritizeDomainDialog.scss';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

type OwnProps = {};

type Props = OwnProps;

const PrioritizeAndDoNotPrioritizeDomainDialog: any = (props:any) => {
    const Props = props;

    const anchor = 'right';

    const [state, setState] = React.useState({
      right: false,
    });

    const toggleDrawer = (anchor:string, open:boolean) => (event:any) => {
      if (
        event &&
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

    const { show } = useSelector(
        (s) => s.needs.prioritizeDialogs.PrioritizeAndDoNotPrioritizeDomainDialog
      );

      return(
        show ? <SwipeableDrawer
        anchor={anchor}
        open={true}
        onClose={toggleDrawer(anchor, false)}
        onOpen={toggleDrawer(anchor, true)}
      >
        <PrioritizeAndDoNotPrioritizeDomainDialogContent />
      </SwipeableDrawer> : ''
      );
};
export default PrioritizeAndDoNotPrioritizeDomainDialog;