import React, { ChangeEvent, FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useGetGoals } from '../../../../helpers/hooks/loading/useGetGoals';
import { getGoalsByPlan, pusherUpdateGoalEvent, updateNewGoalState } from '../../../../store/goals/actions';
import GoalList from '../GoalList/GoalList';
import './GoalPlanning.scss'
import { Icon, InputGroup, Spinner, TextArea } from '@blueprintjs/core';
import _ from "lodash";

import GoalActivity from './GoalActivity/GoalActivity';
import DomainSelector from './DomainSelector/DomainSelector';
import GoalTopBar from './GoalTopBar/GoalTopBar';
import DomainList from './DomainList/DomainList';
import { updateGoal } from '../../../../store/goals/actions';
import Tooltip from '@mui/material/Tooltip';
import { useGetDomainPriorities } from '../../../../helpers/hooks/loading/useGetDomainPriorities';
import useGetDatasetMarksLoading from '../../../../helpers/hooks/loading/useGetDatasetMarksLoading';
import { getDomainPriorities, realTimeDomainPriorityUpdate } from '../../../../store/needs/actions';
import { getSurveyComparison } from '../../../../store/survey-reports/actions';
import useComparison from '../../../surveys/tab/useComparison';
import { isDomainQuestionsComparison } from '../../../surveys/table/utils';
import { DomainDemographicTriageStatuses, DomainPriorityDecision, DomainPriorityTriageStatus, NewGoalTabs, SurveyDemographic, DomainPriority } from '../../../../types';
import { getDomainsById } from '../../../../store/domains/actions';
import { DESIRED_OUTCOME_CHARACTER_LIMIT, EDIT_GOAL_MODE } from '../../../../constants/constants';
import { useGetDatasetsLoading } from '../../../../helpers/hooks/loading/useGetDatasetsLoading';
import ItemLevelInfoAndBookmarksDialog from '../../../team-plan/needs-and-goals/item-level-info-and-bookmarks-dialog/ItemLevelInfoAndBookmarksDialog';
import { getDatasetMarks } from '../../../../store/dataset-marks/actions';
import GoalStrategies from './GoalStrategies/GoalStrategies';
import { getStrategies } from '../../../../store/strategies/actions';
import PriorityEvents from '../../../team-plan/needs-and-goals/prioritize-and-do-not-prioritize-domain-dialog/PrioritizePusherEvents';

type OwnProps = {
  areGoalsAvailable?: boolean;
  loading?: boolean;
  pusher?: any;
  goalPusherGeneralChannel?: any;
};

type Props = OwnProps;

const GoalPlanning: FunctionComponent<Props> = (props) => {
  // CONSTANTS
  const intl = useIntl();
  const dispatch = useDispatch();
  const { areGoalsAvailable, loading, pusher, goalPusherGeneralChannel } = props;
  const SHORT_TITLE_LIMIT = 100;



  // SELECTORS 
  const { goal } = useSelector(s => s.goals.newGoalState);
  const { domainsExistStatus } = useSelector((s) => s.domains); //get from redux store
  const { domainPriorities } = useSelector((s) => s.needs);
  const { surveyComparison } = useSelector((s) => s.surveyReports); //get from redux store
  const { activePlan: plan } = useSelector((s) => s.plans);
  const currentUser = useSelector((s) => s.auth.authenticatedUser);
  const { goalSwitch } = useSelector((s) => s.goals.loading);
  const { goals } = useGetGoals();

  // USE STATE
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [isSideMenuClosed, setIsSideMenuClosed] = useState<boolean>(false);
  const [isStatementEditing, setIsStatementEditing] = useState(false)
  const [isDesiredOutcomeEditing, setIsDesiredOutcomeEditing] = useState(false)
  const [goalName, setGoalName] = useState<string>(goal?.statement ?? '');
  const [desiredOutcome, setDesiredOutcome] = useState<string>(goal?.desired_outcome ?? '');
  const [subscribersData, setSubscribersData] = useState<any>({ subscribers: {} });

  // CUSTOM HOOKS
  const { demographics } = useComparison({
    surveyComparison: surveyComparison,
  });

  // CALL
  useGetDatasetsLoading();
  useGetDomainPriorities();


  // FUNCTIONS
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  const getDomainsRespondentByid = (sdId: number) => {
    const domainIdsArray: number[] = [...prioritizeDomain.map((et) => (et.domain.id)), ...nonPrioritizeDomain.map((et) => (et.domain.id))];
    dispatch(getDomainsById.request({ domainsIds: domainIdsArray, deployment_id: sdId,planId:plan?.id }));// goal?.survey_deployment?.id }));
  };

  const handleGoalNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    value.length <= SHORT_TITLE_LIMIT && setGoalName(value);

  };

  const handleDesiredOutcomeChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    value.length <= DESIRED_OUTCOME_CHARACTER_LIMIT && setDesiredOutcome(value);
  };

  const handleStatementEditing = () => {
    setIsStatementEditing(true)
  }

  const handleDesiredOutcomeEditing = () => {
    setIsDesiredOutcomeEditing(true)
  }

  useEffect(() => {

    const pusherEvent = PriorityEvents.priorityConfirm;
    const pusher = PriorityEvents.pusher;

    const channel = pusher.subscribe(
      PriorityEvents.priorityConfirmChannel
    );
    channel.bind(
      pusherEvent,
      function ( data: { data: {changed_items: [{id:number}]}, } ) {
        dispatch(realTimeDomainPriorityUpdate(data.data.changed_items));
      }
    );
  },[pusher])

  const handleFormSubmit = (type: string) => {
    //statement 
    if (type == 'statement') {
      // make sure the statement has some value in it 
      if (goal && goal.mode === EDIT_GOAL_MODE && goalName.trim() !== '') {
        if (goalName != goal.statement || desiredOutcome != goal.desired_outcome)
          dispatch(
            updateGoal.request({
              ...goal,
              statement: goalName,
              goalId: goal.id,
              user: currentUser?.id,
              planId: goal.plan_id,
              // desired_outcome: desiredOutcome,
              targets: [],
              priority_areas: [],
              survey_deployment: {
                ...goal.survey_deployment,
                survey: goal?.survey_deployment?.survey?.id ?? goal?.survey_deployment?.survey,
              },
              // is_completed:goal.selected_domains.length > 0 && goal.targets.length > 0 && !!goal.desired_outcome && !!goal.statement && !goal.targets.find((et: any) => (!et.target_date || !et.target_value))
            })
          );
        setIsStatementEditing(false)
        // setIsDesiredOutcomeEditing(false)
      };
    }
    if (type == 'desired_outcome') {
      // make sure the statement has some value in it 
      if (goal && goal.mode === EDIT_GOAL_MODE && desiredOutcome.trim() !== '') {
        if (goalName != goal.statement || desiredOutcome != goal.desired_outcome)
          dispatch(
            updateGoal.request({
              ...goal,
              // statement: goalName,
              goalId: goal.id,
              user: currentUser?.id,
              planId: goal.plan_id,
              desired_outcome: desiredOutcome,
              targets: [],
              priority_areas: [],
              survey_deployment: {
                ...goal.survey_deployment,
                survey: goal?.survey_deployment?.survey?.id ?? goal?.survey_deployment?.survey,
              },
              // is_completed:goal.selected_domains.length > 0 && goal.targets.length > 0 && !!goal.desired_outcome && !!goal.statement && !goal.targets.find((et: any) => (!et.target_date || !et.target_value))
            })
          );
        // setIsStatementEditing(false)
        setIsDesiredOutcomeEditing(false)
      };
    }



    //desired_outcome



  };

  const open = useMemo(() => {
    return Boolean(anchorEl);
  }, [anchorEl]);
  const id = useMemo(() => {
    return open ? 'simple-popover' : undefined;
  }, [open]);
  const triageStatusesDictionary: {
    [domainId: string]: DomainDemographicTriageStatuses;
  } = useMemo(() => {
    return _.chain(domainPriorities)
      .groupBy((dP) => dP.domain.id)
      .mapValues<DomainDemographicTriageStatuses>((dPriorities) => {
        const triageStatusByDemographic: {
          [key in SurveyDemographic]?: DomainPriorityTriageStatus | null;
        } = _.chain(demographics)
          .map((demographic) => [
            demographic,
            dPriorities.find((dP) => dP.demographic === demographic)
              ?.triage_status,
          ])
          .fromPairs()
          .value();

        const archived = _.chain(triageStatusByDemographic)
          .values()
          .filter((status) => status != null)
          .every((status) => status === DomainPriorityTriageStatus.Archived)
          .value();

        return {
          ...triageStatusByDemographic,
          archived: archived,
          domainPriorities: dPriorities,
        };
      })
      .value();
  }, [domainPriorities, demographics]);
  const sortedByDomainDataWithoutArchived = useMemo(() => {
    if (!isDomainQuestionsComparison(surveyComparison)) {
      return (
        _.chain(surveyComparison)
          // .filter(
          //   (comparison) =>
          //     !triageStatusesDictionary[comparison.domain.id]?.archived
          // )
          .orderBy((comparison) => comparison?.domain?.name ?? "", ["asc"])
          .value()
      );
    }
    return [];
  }, [surveyComparison, triageStatusesDictionary, demographics]);
  const sortedDomainWithTriageStatus = useMemo(() => {
    if (!sortedByDomainDataWithoutArchived) return [];
    return sortedByDomainDataWithoutArchived.map((et) => {
      let obj: any = { ...et };

      Object.entries(et.respondents).map((dg: any) => {
        const found = domainPriorities.find((dp : any) => {
          return dp.domain.id === et.domain.id && dp.demographic === dg[0];
        });
        obj.respondents[`${dg[0]}`] = {
          ...obj.respondents[`${dg[0]}`],
          triage_status: found?.triage_status,
        };
      });
      return { ...obj };
    });
  }, [sortedByDomainDataWithoutArchived]);
  const [prioritizeDomain, nonPrioritizeDomain] = useMemo(() => {
    const prioritize: any[] = [];
    const nonPrioritize: any[] = [];

    if (sortedDomainWithTriageStatus?.length && triageStatusesDictionary)
      sortedDomainWithTriageStatus?.forEach((dt: any) => {
        const isPrioritied = Object.keys(dt.respondents).find(ett => dt.respondents[ett].triage_status === DomainPriorityTriageStatus.PotentialPriority);
        let domainTriageStatus =
          triageStatusesDictionary[dt.domain.id.toString()];
        const decision = domainTriageStatus?.domainPriorities[0]?.decision;
        if (decision === DomainPriorityDecision.Prioritized && isPrioritied) {
          prioritize.push(dt);
        }
        else {
          nonPrioritize.push(dt);
        }
      });
    return [prioritize, nonPrioritize];
  }, [triageStatusesDictionary, sortedDomainWithTriageStatus]);

  // USE EFFECTS 
  useEffect(() => {
    if (goal && plan && plan.school && plan.school.id)
      dispatch(getDatasetMarks.request({
        survey_deployment_id: goal?.survey_deployment?.id,
        schoolId: plan?.school?.id,
        is_team: true,
        isFiltered: true
      }))
  }, [goal]);

  useEffect(() => {
    setAnchorEl(null);
  },[goals.length,])

  useEffect(() => {
    if (plan && goal) {
      const sdId = goal?.survey_deployment?.id;
      const planId = goal?.plan_id;
      if (sdId && planId) {
        dispatch(
          getDomainPriorities.request({
            plan_id: planId,
            survey_deployment_id: sdId,
          })
        );
        dispatch(
          getSurveyComparison.request({
            survey_deployment_id: [sdId],
            school_id: [goal?.plans?.school?.id], // required review need to fetch it from goal itself
          })
        );
      }
    }
  }, [plan, goal]);

  useEffect(() => {
    if(goal?.desired_outcome){
      setIsDesiredOutcomeEditing(false)
    }
    else{
      setIsDesiredOutcomeEditing(true)
    }
    setGoalName(goal?.statement);
    setDesiredOutcome(goal?.desired_outcome);
  }, [goal])

  // use effect to get already used domains as per selected goal / plan

  // NEED TO UNCOMMENT THIS 
  useEffect(() => {
    if (goal)
      getDomainsRespondentByid(goal.survey_deployment?.id)
  }, [prioritizeDomain, nonPrioritizeDomain]);

  useEffect(() => {
    // if (goal) // NEED TO REMOVE THIS 
    // getDomainsRespondentByid(goal.survey_deployment?.id) // NEED TO REMOVE THIS 
    plan &&
      dispatch(getStrategies.request({ planId: plan?.id }));
  }, [dispatch, plan, plan?.id])

  // if loading goals return spinner
  if (loading) {
    return <Spinner intent={"primary"} />;
  }
  return (
    <div className={`planning ${(isSideMenuClosed ? 'collapse-toggle' : '')}`}>
      <GoalList
        pusher={pusher}
        goalPusherGeneralChannel={goalPusherGeneralChannel}
        isSideMenuClosed={isSideMenuClosed}
        setIsSideMenuClosed={setIsSideMenuClosed}
        setSubscribersData={setSubscribersData} />
      <div className="planning__right">
        {!goalSwitch ? <>
          {!areGoalsAvailable ? <div className="notfound-message no-goal-found">
            <div className="notfound-message_inner">
              <h3>{intl.formatMessage({ id: "app.titles.no-goals-found" })}</h3>
              <p>{intl.formatMessage({ id: "app.titles.new-goal-creation-statement" })}</p>
            </div>
          </div> : <>
            <div className="planing-goal">
              <GoalTopBar subscribersData={subscribersData} />
              {isStatementEditing ?
                <div className="search-label">
                  <InputGroup
                    style={{ border: !goalName ? '2px solid red' : 'none' }}
                    disabled={false}
                    placeholder={intl.formatMessage({ id: 'app.goals.new-goal.steps.name-of-goal' })}
                    value={goalName}
                    onBlur={() => handleFormSubmit('statement')}
                    onChange={handleGoalNameChange}
                    autoFocus={true}
                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        handleFormSubmit('statement')
                      }
                    }}
                  />
                  <span className="max-length">(Max {SHORT_TITLE_LIMIT - goalName?.length}  Characters
                    {goalName?.length !== 0 && " left"})</span>
                </div> :
                goal.statement ?
                  <h2 className="hover-show">{goal.statement}
                    <button onClick={handleStatementEditing}><Icon icon="edit" iconSize={16} /></button>
                  </h2> :
                  <h2>{intl.formatMessage({ id: "app.titles.short-title-not-yet" })}
                    <button onClick={handleStatementEditing}><Icon icon="edit" iconSize={16} /></button>
                  </h2>
              }
              {isDesiredOutcomeEditing ?
                <div className="search-label">
                  <TextArea
                    style={{ border: !desiredOutcome ? '2px solid red' : 'none' }}
                    placeholder={intl.formatMessage({ id: 'app.goals.new-goal.steps.describe-desired-outcome-note' })}
                    autoFocus={true}
                    disabled={false}
                    value={desiredOutcome}
                    onBlur={() => handleFormSubmit('desired_outcome')}
                    onChange={handleDesiredOutcomeChange}
                  />
                  <span className="max-length">(Max {DESIRED_OUTCOME_CHARACTER_LIMIT - desiredOutcome?.length}  Characters
                    {desiredOutcome?.length !== 0 && " left"})</span>
                </div> :
                goal.desired_outcome ?
                  <p className="hover-show">{goal.desired_outcome}
                    <button onClick={handleDesiredOutcomeEditing}><Icon icon="edit" iconSize={14} /></button>
                  </p> :
                  <p className="hover-show">{intl.formatMessage({ id: "app.titles.no-description-yet" })}
                    <button onClick={handleDesiredOutcomeEditing}><Icon icon="edit" iconSize={14} /></button>
                  </p>
              }

              <div className="planing-goal__button-group">
                <button aria-describedby={id} className="stroke-btn" onClick={handleClick}>
                  <Icon icon="small-plus" iconSize={24} /> {intl.formatMessage({ id: 'app.titles.prioritized-other-domain' })}
                </button>
                <DomainSelector
                  anchorEl={anchorEl}
                  handleClick={handleClick}
                  setAnchorEl={setAnchorEl}
                  handleChange={handleChange}
                  expanded={expanded}
                  prioritizeDomain={prioritizeDomain}
                  nonPrioritizeDomain={nonPrioritizeDomain}
                  domainsExistStatus={domainsExistStatus}
                  sortedDomainWithTriageStatus={sortedDomainWithTriageStatus}
                />
              </div>
              <ItemLevelInfoAndBookmarksDialog />
              <div className="planing-goal__domain">
                <DomainList />
              </div>
              <div className="planing-goal__strategies">
                <GoalStrategies />
              </div>
              <div className="planing-goal__activity">
                <GoalActivity />
              </div>
            </div>
          </>}
        </> : <> Loading... </>

        }
      </div>
    </div>
  )
}


export default GoalPlanning;