import React, { FunctionComponent, useMemo, useRef } from "react";
import JoditEditor from "jodit-react";

type OwnProps = {
  content?: string;
  onBlur: (value: string) => void;
  config?: any;
  ref?: any;
  onChange?: (value: string) => void;
};

type Props = OwnProps;

const PageEditor: FunctionComponent<Props> = (props) => {
  const { content, config: pConfig = {}, onBlur, ref } = props;

  const editor = useRef(null);

  const buttons = useMemo(
    () => [
      "source",
      "|",
      "bold",
      "italic",
      "|",
      "ul",
      "ol",
      "|",
      "font",
      "fontsize",
      "brush",
      "paragraph",
      "|",
      "image",
      "table",
      "link",
      "|",
      "left",
      "center",
      "right",
      "justify",
      "|",
      "undo",
      "redo",
      "|",
      "hr",
      "eraser",
    ],
    []
  );
  const config: any = useMemo(
    () => ({
      readonly: false,
      askBeforePasteFromWord: false,
      askBeforePasteHTML: false,
      buttons: buttons,
      buttonsMD: buttons,
      buttonsSM: buttons,
      buttonsXS: buttons,
      ...pConfig,
    }),
    [pConfig]
  );

  const handleContentUpdate = (content: any) => {
    onBlur(content ?? "");
  };

  return (
    <JoditEditor
      ref={ref ?? editor}
      value={content ?? ""}
      config={config}
      onBlur={handleContentUpdate}
      onChange={() => {}}
    />
  );
};

export default PageEditor;
