import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Button, Classes, Dialog, Spinner, Tag } from "@blueprintjs/core";
import {
  getUsers,
  hideAddUserToPlanDialog,
} from "../../../../store/users/actions";
import { TeamRole, UserModel, UserRole } from "../../../../types";
import { addTeamMembers } from "../../../../store/team-members/actions";
import { useLoading } from "../../../../helpers/hooks/useLoading";
import { AppToaster } from "../../../../helpers/toaster";
import UserSelector from "../../../districts/district-users/UserSelector";
import "./AddUserToPlanDialog.scss";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
type OwnProps = {};

type Props = OwnProps;

const AddUserToPlanDialog: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const activePlanId = useSelector((s) => s.plans.activePlan?.id);
  const districtId = useSelector((s) => s.plans.activePlan?.district.id);

  const teamMembers = useSelector((s) => s.teamMembers.teamMembers);

  const users = useSelector((s) => s.users.users?.users);
  const usersLoading = useSelector((s) => s.users.loading.getUsers);

  const usersExcludeTeamMembers: UserModel[] = useMemo(
    () =>
      users?.filter(
        (u) =>
          !teamMembers?.some((tm) => tm.user.id === u.id) &&
          u.profile?.district?.id === districtId &&
          u.is_active
      ) || [],
    [users, teamMembers, districtId]
  );

  const show = useSelector((s) => s.users.showAddUserToPlanDialog);

  const [selectedUser, setSelectedUser] = useState<UserModel | undefined>();

  const handleOpen = () => {
    dispatch(getUsers.request(districtId));
    setSelectedUser(undefined);
  };

  const handleClose = () => {
    dispatch(hideAddUserToPlanDialog());
  };

  const handleAddUserToPlanClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!selectedUser) return null;
    dispatch(
      addTeamMembers.request({
        planId: activePlanId!,
        members: [
          {
            user: selectedUser.id,
            team_role:
              selectedUser.profile.role === UserRole.Principal ||
              selectedUser.profile.role === UserRole.Superintendent
                ? TeamRole.TeamChampion
                : TeamRole.TeamMember,
          },
        ],
      })
    );
  };

  const handleAddTeamMembersSuccess = () => {
    AppToaster.show({
      message: intl.formatMessage(
        { id: "app.titles.toaster-message" },
        {
          name: `${selectedUser?.email}`,
        }
      ),
      icon: "tick",
      intent: "success",
    });
    handleClose();
  };

  const loading = useSelector((s) => s.teamMembers.loading.addTeamMembers);
  const error = useSelector((s) => s.teamMembers.errors.addTeamMembers);
  useLoading({
    loading: loading,
    error: error,
    onSuccess: handleAddTeamMembersSuccess,
  });

  const handleUserChange = useCallback((user?: UserModel) => {
    setSelectedUser(user);
  }, []);

  return (
    <Dialog
      className="addExistinguser"
      isOpen={show}
      onClose={handleClose}
      onOpening={handleOpen}
      // icon={"user"}
      title={intl.formatMessage({
        id: "app.dialogs.add-existing-user",
      })}
    >
      <div className={Classes.DIALOG_BODY}>
        {usersLoading ? (
          <Spinner intent="primary" className="mt-2" />
        ) : (
          <div className={"mb-4"}>
            <form id="add-user-to-plan" onSubmit={handleAddUserToPlanClick}>
              <FormControl sx={{ m: 1, minWidth: 500 }}>
                {/* <Select
                  multiple
                  native
                  // value={selectedUser?.id}
                  onChange={(value) => {
                    const f = usersExcludeTeamMembers?.find(
                      (et) => et.id == value.target.value
                    );
                    handleUserChange(f);
                  }}
                  required={true}
                  inputProps={{
                    id: "select-multiple-native",
                  }}
                >
                  {usersExcludeTeamMembers.map((et) => (
                    <><option key={'teamkey_'+et.id} value={et.id} className="d-inline text-blue-900">
                    {et?.first_name}&nbsp;{et?.last_name} 
                    
                  </option>
                    <option disabled key={'teamkey_'+et.id} value={et.id} className="d-inline text-black bg-gray-400 pl-3 pr-3 pt-1 pb-1 rounded ml-1">
                      {et?.profile?.role}
                      
                    </option>
                    <option disabled></option>
                    </>
                  ))}
                </Select> */}
              <div style={{width:"100%",height:"300px",overflowY:"scroll"}}>
                  {usersExcludeTeamMembers.map((et) => (
                    <ul key={et.id}> {/* Added key prop here to avoid warning */}
                      <li 
                        onClick={() => handleUserChange(et)} 
                        key={'teamkey_' + et.id} 
                        value={et.id} 
                        className={`flex justify-between`} 
                        style={{backgroundColor: et.id === selectedUser?.id ? "#155b98" : ""}}
                      >
                        <span className={`${et.id === selectedUser?.id ? "text-white" : "text-blue-900"}`}>
                          {et?.first_name} {et?.last_name}
                        </span>
                        <span className="customLabel pl-2 pr-2 mr-3">
                          {et?.profile?.role?.replace(/_/g, ' ')}
                        </span>  
                      </li>
                    </ul>
                  ))}
              </div>

 

              </FormControl>
            </form>

            {/* <form id="add-user-to-plan" onSubmit={handleAddUserToPlanClick}>
              <UserSelector
                users={usersExcludeTeamMembers}
                userId={selectedUser?.id}
                onUserChange={handleUserChange}
                placeholder={intl.formatMessage({
                  id: "app.titles.select-user",
                })}
                required={true}
              />
            </form> */}
          </div>
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className="flex justify-center">
          {/* <Button className="w-1/4" onClick={handleClose}>
            {intl.formatMessage({ id: "app.titles.close" })}
          </Button> */}
          <Button
            form="add-user-to-plan"
            className="w-1/4"
            intent="primary"
            type="submit"
            text={intl.formatMessage({ id: "app.titles.add-user" })}
            title={intl.formatMessage({ id: "app.titles.add-user" })}
            loading={loading}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default AddUserToPlanDialog;
