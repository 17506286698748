import { Button, Icon, Spinner } from "@blueprintjs/core";
import _ from "lodash";
import moment from "moment";
import "moment/locale/en-au";
import React, { useEffect, useMemo, useState } from "react";
import { Calendar, Event, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../../../helpers/hooks/useLoading";
import {
  getMeetings,
  showMeetingDialog,
} from "../../../store/meetings/actions";
import { getPlanStrategies } from "../../../store/plan-strategies/actions";
import { Intent } from "@blueprintjs/core/src/common/intent";
import SetUpCalendarTour from "./SetUpCalendarTour";
import CalendarInfoDialog from "./CalendarInfoDialog";
import AvailableEventsCallout from "./AvailableEventsCallout";
import {
  TEAM_PLAN_NAVIGATION_WIDTH,
  TEAM_PLAN_PAGES_PADDING,
} from "../../../constants/constants";
import {
  getBackgroundColorByMeetingType,
  pickTextColorBasedOnBgColor,
} from "../../charts/utils";
import useUserRole from "../../../helpers/hooks/useUserRole";

const localizer = momentLocalizer(moment);

type OwnProps = {
  isAdminView?: boolean;
  calendarMaxWidth?: string | number;
};

type Props = OwnProps;

type CalendarEvent<T = any> = Event & {
  resource: T;
  type?: CalendarEventType;
};

enum CalendarEventType {
  Meeting = "meeting",
  StrategyCoreComponent = "strategy_core_component",
}

const TeamPlanCalendar: React.FC<Props> = (props: Props) => {
  const {
    isAdminView = false,
    calendarMaxWidth = `calc(100vw - ${TEAM_PLAN_NAVIGATION_WIDTH} - ${TEAM_PLAN_PAGES_PADDING} - ${TEAM_PLAN_PAGES_PADDING})`,
  } = props;

  const intl = useIntl();

  const dispatch = useDispatch();

  const [calendarEvents, setCalendarEvents] = useState<CalendarEvent[]>([]);
  const activePlanId = useSelector((s) => s.plans.activePlan?.id);
  const { isDCC, isSuperintendent, user } = useUserRole();

  useEffect(() => {
    if (activePlanId) {
      dispatch(getMeetings.request(activePlanId));
      dispatch(getPlanStrategies.request(activePlanId));
    } else if (isAdminView) {
      dispatch(getMeetings.request(undefined));
    }
  }, [activePlanId, isAdminView]);

  const meetings = useSelector((s) => s.meetings.meetings);
  const loadingGetMeetings = useSelector((s) => s.meetings.loading.getMeetings);
  const errorGetMeetings = useSelector((s) => s.meetings.errors.getMeetings);
  useLoading({ loading: loadingGetMeetings, error: errorGetMeetings });

  const meetingsEvents = useMemo(
    () =>
      meetings.map((meeting) => {
        const startDate = moment(meeting.date);
        /*
          react-big-calendar treats event start/end dates as an exclusive range
          which means that the event spans up to, but not including, the end date.
         */
        const endDate = moment(meeting.end_date).add(1, "second");

        return {
          title: meeting.short_title || meeting.title,
          start: startDate.toDate(),
          end: endDate.toDate(),
          allDay: !!meeting.all_day_event,
          resource: meeting,
          type: CalendarEventType.Meeting,
          system_event: meeting.system_event,
        };
      }),
    [meetings]
  );

  const adminSystemEvents = useMemo(() => {
    return meetingsEvents.filter(
      (event) => event.system_event === true
    );
  }, [meetingsEvents]);

  const planStrategies = useSelector((s) => s.planStrategies.planStrategies);
  const getPlanStrategiesLoading = useSelector(
    (s) => s.planStrategies.loading.getPlanStrategies
  );
  const getPlanStrategiesError = useSelector(
    (s) => s.planStrategies.errors.getPlanStrategies
  );
  useLoading({
    loading: getPlanStrategiesLoading,
    error: getPlanStrategiesError,
  });

  const strategiesEvents = useMemo(() => {
    return _.chain(planStrategies)
      .flatMap((planStrategy) =>
        planStrategy.look_for_statuses
          .filter(
            (status) => status.target_start_date && status.target_end_date
          )
          .map((status) => {
            let title = planStrategy.strategy.name;

            const item = planStrategy.strategy.look_for_items.find(
              (item) => item.id === status.item
            );

            if (item) {
              title += ` (${item.text})`;
            }

            return {
              title: title,
              start: moment(status.target_start_date).toDate(),
              end: moment(status.target_end_date).toDate(),
              allDay: true,
              resource: planStrategy,
              type: CalendarEventType.StrategyCoreComponent,
            };
          })
      )
      .value();
  }, [planStrategies]);


  useEffect(() => {
    if (isAdminView) {
      setCalendarEvents([...adminSystemEvents, ...strategiesEvents]);
    } else {
      setCalendarEvents([...meetingsEvents, ...strategiesEvents]);
    }
  }, [meetingsEvents, strategiesEvents, adminSystemEvents]);

  const handleAddEventClick = (showMeetingTemplates?: boolean) => {
    dispatch(
      showMeetingDialog({
        meeting: undefined,
        showMeetingTemplates: showMeetingTemplates,
        fromAdminView: isAdminView,
      })
    );
  };

  const handleSelectEvent = (
    event: CalendarEvent,
    e: React.SyntheticEvent<HTMLElement, Event>
  ) => {
    switch (event.type) {
      case CalendarEventType.Meeting: {
        dispatch(
          showMeetingDialog({ meeting: event.resource, viewOnly: true })
        );
        break;
      }
      // todo handle other types
    }
  };

  const {
    addEventTitle,
    addEventTitleIntent,
  }: { addEventTitle: string; addEventTitleIntent: Intent } = useMemo(() => {
    const hasNonSystemEvents = calendarEvents.some(
      (cE: any) => !cE.system_event
    );

    if (hasNonSystemEvents) {
      return {
        addEventTitle: intl.formatMessage({
          id: "app.calendar.add-more-suggested-events",
        }),
        addEventTitleIntent: "success",
      };
    }
    return {
      addEventTitle: intl.formatMessage({ id: "app.calendar.set-up-calendar" }),
      addEventTitleIntent: "primary",
    };
  }, [calendarEvents.length]);

  const [showCalendarInfoDialog, setShowCalendarInfoDialog] = useState(false);

  const handleCalendarInfoClick = () => {
    setShowCalendarInfoDialog(true);
  };

  return (
    <>
      {/* {!isAdminView && (
        <AvailableEventsCallout hasEvents={!!calendarEvents.length} />
      )} */}
      <div className="teamContent">
        <div className="flex justify-between">
          <div className="flex space-x-2 mt-5">
            <div className="text-3xl font-bold ">
              {intl.formatMessage({ id: "app.titles.sci-calendar" })}
            </div>
            {!isAdminView && (
              <div className="flex items-center">
                <Button
                  icon={<Icon icon="info-sign" iconSize={30} />}
                  onClick={handleCalendarInfoClick}
                  minimal
                />
              </div>
            )}
          </div>
          <div className={"flex items-center gap-2"}>
            {!isAdminView && (
              <Button
                data-tour={"setup-calenda-step-2-button"}
                data-cy={"setup-calenda-step-2-button"}
                intent={addEventTitleIntent}
                text={addEventTitle}
                title={addEventTitle}
                onClick={() => handleAddEventClick(true)}
                large
              />
            )}
            <Button
              intent="primary"
              text={intl.formatMessage({
                id: "app.calendar.add-custom-event-to-calendar",
              })}
              title={intl.formatMessage({
                id: "app.calendar.add-custom-event-to-calendar",
              })}
              onClick={() => handleAddEventClick()}
              large
            />
          </div>
        </div>
        <hr className="my-2" />
        {loadingGetMeetings || getPlanStrategiesLoading ? (
          <Spinner intent="primary" className="my-2" />
        ) : (
          <div
            className="bg-white p-2"
            style={{
              height: 640,
              maxWidth: calendarMaxWidth,
            }}
          >
            <Calendar<CalendarEvent>
              localizer={localizer}
              events={calendarEvents}
              onSelectEvent={handleSelectEvent as any}
              eventPropGetter={(event) => {
                const meetingType = event.resource?.meeting_type;
                const bgColor = getBackgroundColorByMeetingType(meetingType);
                const textColor = pickTextColorBasedOnBgColor(bgColor);

                return {
                  style: { backgroundColor: bgColor, color: textColor },
                };
              }}
            />
          </div>
        )}
      </div>

      {(!isAdminView && <SetUpCalendarTour />)}
      <CalendarInfoDialog
        show={showCalendarInfoDialog}
        onClose={() => setShowCalendarInfoDialog(false)}
      />
    </>
  );
};

export default TeamPlanCalendar;