import { Icon, Tag } from "@blueprintjs/core";
import React from "react";
import {
  AttachedResource,
  AttachedResourceType,
} from "../../../../../../../../src/types";
import { emptyString } from "react-select/src/utils";

type OwnProps = {
  resources: AttachedResource[];
  onResourceEdit?: (res: AttachedResource) => () => void;
  onResourceRemove?: (resource: AttachedResource) => () => void;
  clickable?: boolean;
  noData?: React.ReactNode;
  lastElement?: React.ReactNode;
  isDeleteIcon?: Boolean;
};

type Props = OwnProps;

const ResourcesListNote: React.FC<Props> = (props) => {
  const {
    resources,
    onResourceEdit,
    onResourceRemove,
    clickable = true,
    noData,
    children,
    lastElement,
    isDeleteIcon = true,
  } = props;

  const handleResourceClick = async (res: AttachedResource) => {
    const isLink = res.type === AttachedResourceType.Link;

    try {
      if (isLink) {
        const url = res.url ?? res.attachment_url; 
        // Open the URL in a new tab
        return window.open(url, "_blank", "noopener,noreferrer");
      }

      const response = await fetch(res.url ?? res.attachment_url ?? ""); 
      if (!response.ok) throw new Error("Network response was not ok");

      // Convert the response to a Blob
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link and trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = res.title; // Specify the desired file name
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download failed", error);
    }
  };

  const handleRemoveClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    res: AttachedResource
  ) => {
    e.stopPropagation();
    !!onResourceRemove && onResourceRemove(res)();
  };

  const getFileExtension = (fileName: any) => {
    return fileName.split(".").pop();
  };
  const getFileIconClass = (fileExtension: any) => {
    switch (fileExtension.toLowerCase()) {
      case "doc":
        return "doc-icon";
      case "docx":
        return "docx-icon";
      case "pdf":
        return "pdf-icon";
      case "jpg":
        return "jpg-icon";
      case "png":
        return "png-icon";
      case "csv":
        return "csv-icon";
      case "xlsx":
        return "xlsx-icon";
      case "ppt":
        return "ppt-icon";
      case "pptx":
        return "pptx-icon";
      case "hyperlink":
        return "hyperlink-icon";
      default:
        return "jpg-icon"; // Default class or no class for unknown extensions
    }
  };

  return (
    <div
      className="flex gap-2"
      style={{
        // width: "600px",
        overflowX: resources.length ? "scroll" : "unset",
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {children}
      {resources.length
        ? resources.map((res) => {
            const fileExtension = getFileExtension(res.title);
            const iconClass = `filesName_Chips ${getFileIconClass(
              fileExtension
            )}`;
            if (res?.type === "upload")
              return (
                <div
                  className="filesWrapper-uploadedFiles"
                  key={res.id}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {res?.type === "upload" && (
                    <div className={iconClass}>
                      <span
                        onDoubleClick={
                          !!onResourceEdit ? onResourceEdit(res) : undefined
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          clickable && handleResourceClick(res);
                        }}
                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                      >
                        {res.title}
                      </span>

                      {isDeleteIcon && (
                        <button
                          className="chipDelete_Btn"
                          onClick={(e) => handleRemoveClick(e, res)}
                        >
                          <Icon icon="cross" iconSize={14} />
                        </button>
                      )}
                    </div>
                  )}
                </div>
              );
          })
        : noData}
      {lastElement}
    </div>
  );
};

export default ResourcesListNote;
