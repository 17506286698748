import { createReducer, RootAction } from "typesafe-actions";
import * as actions from "./actions";
import {
  DomainPriority,
  ItemLevelInfoAndBookmarksDialogType,
  PrioritizeAndDoNotPrioritizeDomainDialogType,
  PrioritizedNeed,
  Priority,
  SurveyDemographic,
  PriorityAreaCheck,
} from "../../types";
import _ from "lodash";
import { getNumberFromLocalStorage, LocalStorageKeys } from "../../constants";
import { triggerPusherPriorityEvent } from "../../api/needs";
import PriorityEvents from "../../components/team-plan/needs-and-goals/prioritize-and-do-not-prioritize-domain-dialog/PrioritizePusherEvents";

type ActionName =
  | "getPrioritizedNeeds"
  | "checkPrioritizedArea"
  | "prioritizeDataset"
  | "deprioritizeDataset"
  | "getDomainPriorities"
  | "addDomainPriority"
  | "updateDomainPriority"
  | "getPriorities"
  | "addPriority"
  | "updatePriority"
  | "deletePriority"
  | "updateDomainPrioritiesDecision"
  | "confirmDomainPriorityStatus";

export type NeedsState = {
  surveyDeploymentId?: number;
  needs: PrioritizedNeed[];
  checkPrioritizedAreas: PriorityAreaCheck;
  domainPriorities: DomainPriority[];
  priorities: Priority[];
  loading: {
    [action in ActionName]?: boolean;
  };
  errors: {
    [action in ActionName]?: Error;
  };

  hovered: {
    hoveredDomainId?: number;
    lockedDomainsIds: {
      [domainId: number]: boolean;
    };
    hiddenDemographics: {
      [key in SurveyDemographic]?: boolean;
    };
  };

  prioritizeDialogs: {
    PrioritizeAndDoNotPrioritizeDomainDialog: {
      show?: boolean;
    } & Partial<PrioritizeAndDoNotPrioritizeDomainDialogType>;
  };

  dialogs: {
    itemLevelInfoAndBookmarksDialog: {
      show?: boolean;
    } & Partial<ItemLevelInfoAndBookmarksDialogType>;
  };
  priorityAreas: {
    priorities: Priority[];
  };
};

const initialState: NeedsState = {
  surveyDeploymentId: getNumberFromLocalStorage(
    LocalStorageKeys.NeedsSelectedSurveyDeploymentId
  ),

  needs: [],

  checkPrioritizedAreas: {
    priority_area_count: 0,
  },

  domainPriorities: [],
  priorities: [],

  hovered: {
    lockedDomainsIds: {},
    hiddenDemographics: {},
  },

  priorityAreas: {
    priorities: [],
  },

  dialogs: {
    itemLevelInfoAndBookmarksDialog: {},
  },

  prioritizeDialogs: {
    PrioritizeAndDoNotPrioritizeDomainDialog: {},
  },

  loading: {},
  errors: {},
};

export const needsReducer = createReducer<NeedsState, RootAction>(initialState)
  // get Priorities
  .handleAction(
    actions.getPriorities.request,
    (state, action): NeedsState => ({
      ...state,
      loading: {
        ...state.loading,
        getPriorities: true,
      },
      errors: {
        ...state.errors,
        getPriorities: undefined,
      },
    })
  )
  .handleAction(
    actions.getPriorities.success,
    (state, action): NeedsState => ({
      ...state,
      priorities: action.payload,
      loading: {
        ...state.loading,
        getPriorities: false,
      },
    })
  )
  .handleAction(
    actions.getPriorities.failure,
    (state, action): NeedsState => ({
      ...state,
      errors: {
        ...state.errors,
        getPriorities: action.payload,
      },
      loading: {
        ...state.loading,
        getPriorities: false,
      },
    })
  )
  // add priority
  .handleAction(
    actions.addPriority.request,
    (state, action): NeedsState => ({
      ...state,
      loading: {
        ...state.loading,
        addPriority: true,
      },
      errors: {
        ...state.errors,
        addPriority: undefined,
      },
    })
  )
  .handleAction(
    actions.addPriority.success,
    (state, action): NeedsState => ({
      ...state,
      priorities: [...state.priorities, action.payload.priority],
      priorityAreas: {
        ...state.priorityAreas,
        priorities: state.priorityAreas.priorities.map((p) =>
          action.payload.localId && action.payload.localId === p.id
            ? action.payload.priority
            : p
        ),
      },
      loading: {
        ...state.loading,
        addPriority: false,
      },
    })
  )
  .handleAction(
    actions.addPriority.failure,
    (state, action): NeedsState => ({
      ...state,
      errors: {
        ...state.errors,
        addPriority: action.payload,
      },
      loading: {
        ...state.loading,
        addPriority: false,
      },
    })
  )
  // update priority
  .handleAction(
    actions.updatePriority.request,
    (state, action): NeedsState => ({
      ...state,
      loading: {
        ...state.loading,
        updatePriority: true,
      },
      errors: {
        ...state.errors,
        updatePriority: undefined,
      },
    })
  )
  .handleAction(
    actions.updatePriority.success,
    (state, action): NeedsState => ({
      ...state,
      priorities: state.priorities.map((p) =>
        p.id === action.payload.id ? action.payload : p
      ),
      priorityAreas: {
        ...state.priorityAreas,
        priorities: state.priorityAreas.priorities.map((p) =>
          p.id === action.payload.id ? action.payload : p
        ),
      },
      loading: {
        ...state.loading,
        updatePriority: false,
      },
    })
  )
  .handleAction(
    actions.updatePriority.failure,
    (state, action): NeedsState => ({
      ...state,
      errors: {
        ...state.errors,
        updatePriority: action.payload,
      },
      loading: {
        ...state.loading,
        updatePriority: false,
      },
    })
  )
  // delete priority
  .handleAction(
    actions.deletePriority.request,
    (state, action): NeedsState => ({
      ...state,
      loading: {
        ...state.loading,
        deletePriority: true,
      },
      errors: {
        ...state.errors,
        deletePriority: undefined,
      },
    })
  )
  .handleAction(
    actions.deletePriority.success,
    (state, action): NeedsState => ({
      ...state,
      priorities: state.priorities.filter((p) => p.id !== action.payload),
      priorityAreas: {
        ...state.priorityAreas,
        priorities: state.priorityAreas.priorities.filter(
          (p) => p.id !== action.payload
        ),
      },
      loading: {
        ...state.loading,
        deletePriority: false,
      },
    })
  )
  .handleAction(
    actions.deletePriority.failure,
    (state, action): NeedsState => ({
      ...state,
      errors: {
        ...state.errors,
        deletePriority: action.payload,
      },
      loading: {
        ...state.loading,
        deletePriority: false,
      },
    })
  )

  // initialize priority areas priorities
  .handleAction(
    actions.initializePriorityAreasPriorities,
    (state, action): NeedsState => ({
      ...state,
      priorityAreas: {
        ...state.priorityAreas,
        priorities: action.payload,
      },
    })
  )
  // realtime domain priority update
  .handleAction(
    actions.realtimeUpdateDomainPriority,
    (state, action): NeedsState => {
      return {
        ...state,
        domainPriorities: !action.payload.is_new ? state.domainPriorities.map((dM) =>
          dM.id === action.payload.data.id ? action.payload.data : dM
        ) : [...state.domainPriorities, action.payload.data]
      }
    }
  )
  // add priority to priority areas
  .handleAction(
    actions.addPriorityToPriorityAreas,
    (state, action): NeedsState => ({
      ...state,
      priorityAreas: {
        ...state.priorityAreas,
        priorities: [action.payload, ...state.priorityAreas.priorities],
      },
    })
  )
  // get prioritized needs
  .handleAction(
    actions.getPrioritizedNeeds.request,
    (state, action): NeedsState => ({
      ...state,
      loading: {
        ...state.loading,
        getPrioritizedNeeds: true,
      },
      errors: {
        ...state.errors,
        getPrioritizedNeeds: undefined,
      },
    })
  )
  .handleAction(
    actions.getPrioritizedNeeds.success,
    (state, action): NeedsState => ({
      ...state,
      needs: action.payload,
      loading: {
        ...state.loading,
        getPrioritizedNeeds: false,
      },
    })
  )
  .handleAction(
    actions.getPrioritizedNeeds.failure,
    (state, action): NeedsState => ({
      ...state,
      errors: {
        ...state.errors,
        getPrioritizedNeeds: action.payload,
      },
      loading: {
        ...state.loading,
        getPrioritizedNeeds: false,
      },
    })
  )

  // is_priority_area_exists
  .handleAction(
    actions.checkPrioritizedArea.request,
    (state, action): NeedsState => ({
      ...state,
      loading: {
        ...state.loading,
        checkPrioritizedArea: true,
      },
      errors: {
        ...state.errors,
        checkPrioritizedArea: undefined,
      },
    })
  )
  .handleAction(
    actions.checkPrioritizedArea.success,
    (state, action): NeedsState => ({
      ...state,
      checkPrioritizedAreas: action.payload,
      loading: {
        ...state.loading,
        checkPrioritizedArea: false,
      },
    })
  )
  .handleAction(
    actions.checkPrioritizedArea.failure,
    (state, action): NeedsState => ({
      ...state,
      errors: {
        ...state.errors,
        checkPrioritizedArea: action.payload,
      },
      loading: {
        ...state.loading,
        checkPrioritizedArea: false,
      },
    })
  )

  // get Domain Priorities
  .handleAction(
    actions.getDomainPriorities.request,
    (state, action): NeedsState => ({
      ...state,
      loading: {
        ...state.loading,
        getDomainPriorities: true,
      },
      errors: {
        ...state.errors,
        getDomainPriorities: undefined,
      },
    })
  )
  .handleAction(
    actions.getDomainPriorities.success,
    (state, action): NeedsState => ({
      ...state,
      domainPriorities: action.payload,
      loading: {
        ...state.loading,
        getDomainPriorities: false,
      },
    })
  )
  .handleAction(
    actions.getDomainPriorities.failure,
    (state, action): NeedsState => ({
      ...state,
      errors: {
        ...state.errors,
        getDomainPriorities: action.payload,
      },
      loading: {
        ...state.loading,
        getDomainPriorities: false,
      },
    })
  )
  .handleAction(
    actions.realTimeDomainPriorityUpdate,
    (state, action): NeedsState => {
      const updatedDomainPriorities = state.domainPriorities.map((domainPrioritiy : any) => {
        const result = action.payload.find((changedItem : any) => changedItem.id === domainPrioritiy.id);
        return result ?  result : domainPrioritiy;
      });
      const newState = {
        ...state,
        domainPriorities : updatedDomainPriorities
      }
      return newState;
    }
  )
  //add Domain Priority
  .handleAction(
    actions.addDomainPriority.request,
    (state, action): NeedsState => ({
      ...state,
      loading: {
        ...state.loading,
        addDomainPriority: true,
      },
      errors: {
        ...state.errors,
        addDomainPriority: undefined,
      },
    })
  )
  .handleAction(
    actions.addDomainPriority.success,
    (state, action): NeedsState => ({
      ...state,
      domainPriorities: [...state.domainPriorities, action.payload],
      loading: {
        ...state.loading,
        addDomainPriority: false,
      },
    })
  )
  .handleAction(
    actions.addDomainPriority.failure,
    (state, action): NeedsState => ({
      ...state,
      errors: {
        ...state.errors,
        addDomainPriority: action.payload,
      },
      loading: {
        ...state.loading,
        addDomainPriority: false,
      },
    })
  )
  //update Domain Priority
  .handleAction(
    actions.updateDomainPriority.request,
    (state, action): NeedsState => ({
      ...state,
      loading: {
        ...state.loading,
        updateDomainPriority: true,
      },
      errors: {
        ...state.errors,
        updateDomainPriority: undefined,
      },
    })
  )
  .handleAction(
    actions.updateDomainPriority.success,
    (state, action): NeedsState => ({
      ...state,
      domainPriorities: state.domainPriorities.map((dM) =>
        dM.id === action.payload.id ? action.payload : dM
      ),
      loading: {
        ...state.loading,
        updateDomainPriority: false,
      },
    })
  )
  .handleAction(
    actions.updateDomainPriority.failure,
    (state, action): NeedsState => ({
      ...state,
      errors: {
        ...state.errors,
        updateDomainPriority: action.payload,
      },
      loading: {
        ...state.loading,
        updateDomainPriority: false,
      },
    })
  )
  // updating domain priorities confirm status
  .handleAction(
    actions.confirmDomainPriorityStatus.request,
    (state, action): NeedsState => ({
      ...state,
      loading: {
        ...state.loading,
        confirmDomainPriorityStatus: true,
      },
      errors: {
        ...state.errors,
        confirmDomainPriorityStatus: undefined,
      },
    })
  )
  .handleAction(
    actions.confirmDomainPriorityStatus.success,
    (state, action): NeedsState => ({
      ...state,
      loading: {
        ...state.loading,
        confirmDomainPriorityStatus: false,
      },
    })
  )
  .handleAction(
    actions.confirmDomainPriorityStatus.failure,
    (state, action): NeedsState => ({
      ...state,
      errors: {
        ...state.errors,
        confirmDomainPriorityStatus: action.payload,
      },
      loading: {
        ...state.loading,
        confirmDomainPriorityStatus: false,
      },
    })
  )

  // update domain priorities decision
  .handleAction(
    actions.updateDomainPrioritiesDecision.request,
    (state, action): NeedsState => ({
      ...state,
      loading: {
        ...state.loading,
        updateDomainPrioritiesDecision: true,
      },
      errors: {
        ...state.errors,
        updateDomainPrioritiesDecision: undefined,
      },
    })
  )
  .handleAction(
    actions.updateDomainPrioritiesDecision.success,
    (state, action): NeedsState => {
      try{
        triggerPusherPriorityEvent(action.payload.changed_items[0].plan, {
          event: PriorityEvents.priorityConfirm,
          channel_name: PriorityEvents.priorityConfirmChannel,
          data: action.payload
        });
      } catch (err) {
        console.log(err, 'ERROR goal event for priority confirm');
      }
      const newState = {
        ...state,
        domainPriorities: action.payload.changed_items.length
          ? state.domainPriorities.map((dP) => {
            const changedItem = action.payload.changed_items.find(
              (cI) => cI.id === dP.id
            );
  
            return changedItem ?? dP;
          })
          : state.domainPriorities,
        loading: {
          ...state.loading,
          updateDomainPrioritiesDecision: false,
        },
      }
      return newState;
    }
  )
  .handleAction(
    actions.updateDomainPrioritiesDecision.failure,
    (state, action): NeedsState => ({
      ...state,
      errors: {
        ...state.errors,
        updateDomainPrioritiesDecision: action.payload,
      },
      loading: {
        ...state.loading,
        updateDomainPrioritiesDecision: false,
      },
    })
  )

  .handleAction(
    actions.realtimeUpdateDomainPriorityDecision,
    (state, action): NeedsState => ({
      ...state,
      domainPriorities: action.payload.changed_items.length
        ? state.domainPriorities.map((dP) => {
          const changedItem = action.payload.changed_items.find(
            (cI) => cI.id === dP.id
          );

          return changedItem ?? dP;
        })
        : state.domainPriorities,
    })
  )
  // prioritize dataset
  .handleAction(
    actions.prioritizeDataset.request,
    (state, action): NeedsState => ({
      ...state,
      loading: {
        ...state.loading,
        prioritizeDataset: true,
      },
      errors: {
        ...state.errors,
        prioritizeDataset: undefined,
      },
    })
  )
  .handleAction(
    actions.prioritizeDataset.success,
    (state, action): NeedsState => ({
      ...state,
      needs: _.chain([...state.needs, ...action.payload])
        .uniqBy((t) => t.id)
        .value(),
      loading: {
        ...state.loading,
        prioritizeDataset: false,
      },
    })
  )
  .handleAction(
    actions.prioritizeDataset.failure,
    (state, action): NeedsState => ({
      ...state,
      errors: {
        ...state.errors,
        prioritizeDataset: action.payload,
      },
      loading: {
        ...state.loading,
        prioritizeDataset: false,
      },
    })
  )
  // deprioritize dataset
  .handleAction(
    actions.deprioritizeDataset.request,
    (state, action): NeedsState => ({
      ...state,
      loading: {
        ...state.loading,
        deprioritizeDataset: true,
      },
      errors: {
        ...state.errors,
        deprioritizeDataset: undefined,
      },
    })
  )
  .handleAction(
    actions.deprioritizeDataset.success,
    (state, action): NeedsState => ({
      ...state,
      needs: state.needs.filter((need) => !action.payload.includes(need.id)),
      loading: {
        ...state.loading,
        deprioritizeDataset: false,
      },
    })
  )
  .handleAction(
    actions.deprioritizeDataset.failure,
    (state, action): NeedsState => ({
      ...state,
      errors: {
        ...state.errors,
        deprioritizeDataset: action.payload,
      },
      loading: {
        ...state.loading,
        deprioritizeDataset: false,
      },
    })
  )
  // change survey deployment id on needs page
  .handleAction(
    actions.changeSurveyDeploymentIdOnNeedsPage,
    (state, action): NeedsState => {
      localStorage.setItem(
        LocalStorageKeys.NeedsSelectedSurveyDeploymentId,
        action.payload as any
      );
      return {
        ...state,
        surveyDeploymentId: action.payload,
      };
    }
  )
  .handleAction(
    actions.showItemLevelInfoAndBookmarksDialog,
    (state, action): NeedsState => {
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          itemLevelInfoAndBookmarksDialog: {
            show: true,
            ...action.payload,
          },
        },
      };
    }
  )
  .handleAction(
    actions.hideItemLevelInfoAndBookmarksDialog,
    (state, action): NeedsState => {
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          itemLevelInfoAndBookmarksDialog: {},
        },
      };
    }
  )
  .handleAction(
    actions.showPrioritizeAndDoNotPrioritizeDomainDialog,
    (state, action): NeedsState => {
      return {
        ...state,
        prioritizeDialogs: {
          ...state.prioritizeDialogs,
          PrioritizeAndDoNotPrioritizeDomainDialog: {
            show: true,
            ...action.payload,
          },
        },
      };
    }
  )
  .handleAction(
    actions.hidePrioritizeAndDoNotPrioritizeDomainDialog,
    (state, action): NeedsState => {
      return {
        ...state,
        prioritizeDialogs: {
          ...state.prioritizeDialogs,
          PrioritizeAndDoNotPrioritizeDomainDialog: {},
        },
      };
    }
  )
  .handleAction(
    actions.changeHoveredDomainId,
    (state, action): NeedsState => {
      return {
        ...state,
        hovered: {
          ...state.hovered,
          hoveredDomainId: action.payload,
        },
      };
    }
  )
  .handleAction(
    actions.toggleDemographicHidden,
    (state, action): NeedsState => {
      return {
        ...state,
        hovered: {
          ...state.hovered,
          hiddenDemographics: {
            ...state.hovered.hiddenDemographics,
            [action.payload]: !state.hovered.hiddenDemographics[action.payload],
          },
        },
      };
    }
  )
  .handleAction(
    actions.toggleDomainLockById,
    (state, action): NeedsState => {
      return {
        ...state,
        hovered: {
          ...state.hovered,
          lockedDomainsIds: {
            ...state.hovered.lockedDomainsIds,
            [action.payload]: !state.hovered.lockedDomainsIds[action.payload],
          },
        },
      };
    }
  );