import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Icon } from "@blueprintjs/core";
import { hideSurveyDeploymentDialog } from "../../../store/surveys-deployment/actions";
import { setSelectedSurvey } from "../../../store/surveys/actions";
import SurveyDeploymentDialogContent from "./SurveyDeploymentDialogContent";
import useCreateSurveyDeploymentLoading from "../../../helpers/hooks/loading/useCreateSurveyDeploymentLoading";
import { useUpdateSurveyDeploymentLoading } from "../../../helpers/hooks/loading/useUpdateSurveyDeploymentLoading";
import { styled } from "@mui/material/styles";
// import Dialog from "@mui/material/Dialog";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2)
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1)
    }, "& .MuiPaper-root": {
      width: "100%", // Set the desired width here
      maxWidth: "100%", // Adjust max-width to make it responsive
    },
  }));

type OwnProps = {
  isTeamPlanDialog?: boolean;
};

type Props = OwnProps;

const SurveyDeploymentDialog: React.FC<Props> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { isTeamPlanDialog } = props;

  const selectedSurvey = useSelector((s) => s.surveys.selectedSurvey);
  const surveyDeployment = useSelector(
    (s) => s.surveysDeployment.selectedSurveyDeployment
  );

  const show = useSelector(
    (s) => s.surveysDeployment.showSurveyDeploymentDialog
  );

  const handleClose = () => {
    if (selectedSurvey) {
      dispatch(setSelectedSurvey(undefined));
    }
    dispatch(hideSurveyDeploymentDialog());
  };

  const { loading: loadingAdd } = useCreateSurveyDeploymentLoading({
    onClose: handleClose,
  });
  const { loading: loadingUpdate } = useUpdateSurveyDeploymentLoading({
    onClose: handleClose,
  });

  const dialogIcon = useMemo(() => {
    return (
      <Icon icon={surveyDeployment ? "edit" : "properties"} iconSize={24} />
    );
  }, [surveyDeployment]);

  return (
    
    <Dialog
      className="w-1/2 bg-white"
      style={{ minWidth: "750px" }}
      enforceFocus={false}
      onClose={handleClose}
      canOutsideClickClose={true}
      canEscapeKeyClose={true}
      
      isOpen={show?show:false}
      isCloseButtonShown={false}
    >
      <SurveyDeploymentDialogContent
        selectedSurvey={selectedSurvey}
        surveyDeployment={surveyDeployment}
        isTeamPlanDialog={isTeamPlanDialog}
        loading={loadingAdd || loadingUpdate}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default SurveyDeploymentDialog;