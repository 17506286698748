import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { Checkbox, FormGroup } from "@blueprintjs/core";
import SurveySelector from "../selectors/SurveySelector";
import MultiDistrictSelector from "../selectors/MultiDistrictSelector";
import { useSelector } from "react-redux";
import SurveyDeploymentOpenTimePeriods from "../dialogs/SurveyDeploymentOpenTimePeriods";
import { OpenTimePeriod, School, SurveyDeploymentPassword } from "../../../types";

type OwnProps = {
  surveyId: number | undefined;
  onSurveyIdChange: (selectedSurveyId?: number) => void;
  districtIds: number[];
  onDistrictChange: (selectedDistricts?: number[]) => void;
  isGlobal?: boolean;
  onGlobalChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  isTeamPlanDialog?: boolean;

  openTimePeriods: OpenTimePeriod[];
  setOpenTimePeriods: React.Dispatch<React.SetStateAction<OpenTimePeriod[]>>;

  schoolsIds: number[];
  setSchoolsIds: React.Dispatch<React.SetStateAction<number[]>>;
  schoolsOptions: School[];
  schoolsDictionary: {
    [school: number]: School;
  };
  creating: boolean;
  passwords: Omit<SurveyDeploymentPassword, "created_at" | "updated_at">[];
  setPasswords: React.Dispatch<
    React.SetStateAction<
      Omit<SurveyDeploymentPassword, "created_at" | "updated_at">[]
    >
  >;
};

type Props = OwnProps;

const SurveyDeploymentGeneralInfoTab: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const {
    surveyId,
    onSurveyIdChange,
    isGlobal,
    onGlobalChange,
    districtIds,
    onDistrictChange,
    isTeamPlanDialog,
    openTimePeriods,
    setOpenTimePeriods,

    schoolsIds,
    setSchoolsIds,
    schoolsDictionary,
    schoolsOptions,
    creating,
    passwords,
    setPasswords
  } = props;

  const selectedSurvey = useSelector((s) => s.surveys.selectedSurvey);

  const surveyDeployment = useSelector(
    (s) => s.surveysDeployment.selectedSurveyDeployment
  );

  const isSurveySelectorDisabled = useMemo(() => {
    return isTeamPlanDialog && (!!selectedSurvey || !!surveyDeployment);
  }, [selectedSurvey, isTeamPlanDialog]);

  const selectedSurveyOptionType = useMemo(() => {
    return !!selectedSurvey
      ? { label: selectedSurvey.name, value: selectedSurvey.id }
      : !!surveyDeployment
      ? {
          label: surveyDeployment?.survey?.name,
          value: surveyDeployment?.survey?.id,
        }
      : undefined;
  }, [selectedSurvey]);

  const existingOpenTimePeriods = useMemo(() => {
    return surveyDeployment?.school_open_periods?.map((op) => ({
      ...op,
      school: op.school.id,
    }));
  }, [surveyDeployment]);

  return (
    <>
      {/* <FormGroup
        label={intl.formatMessage({
          id: "app.forms.survey-deployment-form.survey-selector",
        })}
        labelFor="survey-selector"
      >
        <SurveySelector
          surveyId={surveyId}
          onSurveyChange={onSurveyIdChange}
          isDisabled={isSurveySelectorDisabled}
          selectedSurvey={selectedSurveyOptionType}
        />
      </FormGroup> */}

      {/* {!isTeamPlanDialog && (
        <>
          <FormGroup>
            <Checkbox
              checked={isGlobal}
              label={intl.formatMessage({
                id: "app.forms.survey-deployment-form.is-global",
              })}
              disabled={!!districtIds.length}
              onChange={onGlobalChange}
            />
          </FormGroup>
          <FormGroup
            label={intl.formatMessage({
              id: "app.forms.survey-deployment-form.districts-selector",
            })}
          >
            <MultiDistrictSelector
              disabled={isGlobal}
              onDistrictChange={onDistrictChange}
              districtIds={districtIds}
            />
          </FormGroup>
        </>
      )} */}

      <SurveyDeploymentOpenTimePeriods
        onDistrictChange={onDistrictChange}
        isTeamPlanDialog={isTeamPlanDialog}
        existingOpenTimePeriods={existingOpenTimePeriods}
        districtIds={districtIds}
        openTimePeriods={openTimePeriods}
        setOpenTimePeriods={setOpenTimePeriods}
        schoolsIds={schoolsIds}
        setSchoolsIds={setSchoolsIds}
        schoolsDictionary={schoolsDictionary}
        schoolsOptions={schoolsOptions}
        creating={creating}
        passwords={passwords}
        setPasswords={setPasswords}
      />
    </>
  );
};

export default SurveyDeploymentGeneralInfoTab;