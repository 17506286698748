import React, {
  CSSProperties,
  FunctionComponent,
  useMemo,
  useState,
} from "react";
import { Button, Card, Divider } from "@blueprintjs/core";
import { pickTextColorBasedOnBgColor } from "../../../charts/utils";
import _ from "lodash";
import BookmarkNumIcon from "./BookmarkNumIcon";
import useDomainPriority from "./useDomainPriority";
import { BookmarkType, Domain, SurveyDemographic, DomainPriorityDecision } from "../../../../types";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  changeHoveredDomainId,
  showPrioritizeAndDoNotPrioritizeDomainDialog,
} from "../../../../store/needs/actions";
import useDomainLevelBookmarkCounter from "../../../../helpers/hooks/bookmarks/useDomainLevelBookmarkCounter";
import { DEFAULT_PRECISION } from "../../../../constants/constants";
import Grid from "@mui/material/Grid";

type OwnProps = {
  /**
   * domain.id is used to match an existing DomainPriority when updating or
   * creating a new one, as well as for highlighting functionality.
   * domain.color_hex is used for the background color of the pill
   * that contains the meanScore
   */
  domain: Domain;
  /**
   * Used to match an existing DomainPriority when updating or creating a new one
   */
  demographic: SurveyDemographic;
  meanScore?: number;
  isDomainPrioritized?:any;
  /**
   * Used to match an existing DomainPriority when updating or creating a new one
   */
  surveyDeploymentId?: number;
  /**
   * Has the highest priority when calculating opacity in highlighting functionality
   */
  hiddenOnDemographicLevel?: boolean;
  showRedBorder?: boolean;
  isPriorityConfirmed?: boolean;
};

type Props = OwnProps;

const CARD_HOVER_EFFECT_TIMEOUT = 500;

/**
 * When hovering over the title of the card displays a button that disables
 * opacity change of the card when highlighting other cards with other
 * domain.id except hiddenOnDemographicLevel=true
 */
const DomainComparisonCard: FunctionComponent<Props> = (props) => {
  const {
    domain,
    demographic,
    meanScore,
    surveyDeploymentId,
    hiddenOnDemographicLevel,
    showRedBorder,
    isPriorityConfirmed,
    isDomainPrioritized
  } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    loading: loading,
    archived: archived,
    prioritized: prioritized,
    onArchiveClick,
    onPotentialPriorityClick,
  } = useDomainPriority({
    domainId: domain.id,
    surveyDeploymentId: surveyDeploymentId,
    demographic: demographic,
  });

  const scoreTitle = useMemo(() => {
    return meanScore != null
      ? _.round(meanScore, DEFAULT_PRECISION).toFixed(2)
      : intl.formatMessage({
          id: "app.titles.not-applicable",
        });
  }, [meanScore]);

  const colorBasedStyles = useMemo((): CSSProperties => {
    return {
      background: domain.color_hex,
      color: domain.color_hex
        ? pickTextColorBasedOnBgColor(domain.color_hex)
        : undefined,
    };
  }, [domain.color_hex]);

  const { marksCountByDemographic } = useDomainLevelBookmarkCounter({
    domainId: domain.id,
  });

  const marksCount = useMemo(() => marksCountByDemographic[demographic] ?? {}, [
    marksCountByDemographic,
    demographic,
  ]);

  const { dismissed, domainPrioritized, hasPriorities } = useMemo(() => {
    let defaultValues = {
      dismissed: false,
      domainPrioritized: false,
      hasPriorities: !!isDomainPrioritized?.domainPriorities
        ?.length,
    };

    const decision =
      isDomainPrioritized?.domainPriorities[0]?.decision;

    switch (decision) {
      case DomainPriorityDecision.Prioritized: {
        return {
          ...defaultValues,
          domainPrioritized: true,
        };
      }
      case DomainPriorityDecision.Dismissed: {
        return {
          ...defaultValues,
          dismissed: true,
        };
      }
      default: {
        return defaultValues;
      }
    }
  }, [isDomainPrioritized?.domainPriorities]);

  const handleCardClick = () => {
    // if (!archived) {
    dispatch(
      showPrioritizeAndDoNotPrioritizeDomainDialog({
        domain: domain,
        demographic: demographic,
        meanScore: meanScore,
        marksCount: marksCount,
        prioritized: prioritized
      })
    );
    // }
  };

const domainPriorityCheck = useMemo(() => {
  const decision = isDomainPrioritized?.domainPriorities?.find(
    (dp: any) => dp.domain.id === domain.id && dp.demographic === demographic
  )?.decision;

  switch (decision) {
    case DomainPriorityDecision.Prioritized: {
      return true;
    }
    case DomainPriorityDecision.Dismissed: {
      return false;
    }
    default:
      return null;
  }
}, [isDomainPrioritized?.domainPriorities, domain, demographic]);

  return (
    <Grid item xs={12} md={3}>
    <div className="domain-card"
      onClick={handleCardClick}>
      <div className="domain-card-header">
        <h5 className="domain-card-title">{domain.name}</h5>
        <div className="timebox" style={{
            ...colorBasedStyles,
            minWidth: "3.5rem",
          }}>{scoreTitle}</div>
        </div>
        <div className="domain-card-footer">
          <h5 className={`${ domainPriorityCheck ? 'card-status active' : 'card-status' } `}>{domainPriorityCheck ? 'Prioritized' : domainPriorityCheck === false  ? 'Not Prioritized':""}</h5>
          <div className="rank-card">
            <BookmarkNumIcon
              count={marksCount[BookmarkType.Positive]}
              bookmarkType={BookmarkType.Positive}
            />
            <BookmarkNumIcon
              count={marksCount[BookmarkType.Negative]}
              bookmarkType={BookmarkType.Negative}
            />
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default DomainComparisonCard;