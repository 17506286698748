import React, { useRef } from "react";
import { Checkbox } from "@mui/material";

const TableCheckboxCell = React.forwardRef(
  ({ indeterminate, ...rest }: any, ref: any) => {
    const defaultRef = useRef<HTMLInputElement>(null);
    const resolvedRef = ref || defaultRef;

    return (
      <Checkbox
        ref={resolvedRef}
        className="mb-0"
        indeterminate={indeterminate}
        {...rest}
      />
    );
  }
);
export default TableCheckboxCell;
