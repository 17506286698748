import { Button, ButtonGroup } from "@blueprintjs/core";
import React, { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { SurveyDemographic } from "../../../../types";
import { Checkbox, FormControlLabel } from "@mui/material";

type OwnProps = {
  handleDemographicsFilterChange: (demographic: SurveyDemographic) => void;
  surveyDepsDemographics: SurveyDemographic[][];
  filteredDemographics: SurveyDemographic[];
};

type Props = OwnProps;

const DistrictComaprisonHeaderFilter: React.FC<Props> = (props) => {
  const MINIMUM_FILTER_APPLIED = 1;
  const intl = useIntl();
  const {
    surveyDepsDemographics,
    handleDemographicsFilterChange,
    filteredDemographics,
  } = props;

  const uniqueDemographics = useMemo(() => {
    let uniqueDemographics: SurveyDemographic[] = [];
    surveyDepsDemographics.forEach((item: SurveyDemographic[]) =>
      item.forEach((d: SurveyDemographic) => {
        if (uniqueDemographics.indexOf(d) === -1) {
          uniqueDemographics.push(d);
        }
      })
    );
    return uniqueDemographics;
  }, [surveyDepsDemographics]);

  useEffect(() => {
    if(!filteredDemographics.length){
      uniqueDemographics.map((element: SurveyDemographic)=>handleDemographicsFilterChange(element ))
    }
      
  }, [uniqueDemographics])
  
  const disableFilter = (element:SurveyDemographic) => {
    // Check if the current element is in filteredDemographics
    if (filteredDemographics.indexOf(element) !== -(MINIMUM_FILTER_APPLIED)) {
      // If the element is in filteredDemographics, disable it only if it's the only filter applied
      return filteredDemographics.length === MINIMUM_FILTER_APPLIED;
    }
    return false; // If the element is not filtered, do not disable it
  };

  return (
    <ButtonGroup large className="flex-wrap">
      {uniqueDemographics.map((element: SurveyDemographic, i: number) => (
        <FormControlLabel
        key={i}

        value="top"
        control={<Checkbox
          checked={filteredDemographics.includes(element)}
          disabled={disableFilter(element)}
          onClick={() => handleDemographicsFilterChange(element)}
          
          />}
        label={intl.formatMessage({
          id: `app.filters.respondent-type.distric-comparison.${element}`,
        })}
        labelPlacement="end"
      />
      ))}
    </ButtonGroup>
  );
};

export default DistrictComaprisonHeaderFilter;