import React from "react";
import moment from "moment";
import { Divider } from "@blueprintjs/core";
import {
  District,
  Survey,
  SurveyDemographic,
  SurveyDeployment,
} from "../../../../types";
import { useIntl } from "react-intl";

type OwnProps = {
  isTeamPlanTable?: boolean;
  dateField: "start_date" | "end_date" | "created_at";
  surveyDeployment: SurveyDeployment<District, Survey<number>> & {
    availableDemographics: SurveyDemographic[];
  };
};
type Props = OwnProps;

const SurveyOpenPeriodDateCell: React.FC<Props> = (props) => {
  const { isTeamPlanTable, surveyDeployment, dateField } = props;

  const intl = useIntl();

  if (!isTeamPlanTable) {
    if (surveyDeployment[dateField] == null) {
      return <>{intl.formatMessage({ id: "app.titles.not-applicable" })}</>;
    }

    return (
      <div>{moment(surveyDeployment[dateField]).format("MM/DD/YYYY")}</div>
    );
  }
  const { availableDemographics } = surveyDeployment;
  return (
            <p>
      {availableDemographics.length
        ? availableDemographics.map((d, i) => {
          const openPeriod = surveyDeployment?.school_open_periods?.find(
            (op) => op.demographic === d
          );
          return (
            <div key={i}>
              {openPeriod ? (
                <p>{moment(openPeriod[dateField]).format("MM/DD/YY")}</p>
              ) : (
                <div className="font-bold flex justify-center">&#8722;</div>
              )}
              
            </div>
          );
        })
        : intl.formatMessage({ id: "app.titles.not-applicable" })}
        </p>
    
  );
};
export default SurveyOpenPeriodDateCell;