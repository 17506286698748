import { createAction, createAsyncAction } from "typesafe-actions";
import {
  DomainPriority,
  ItemLevelInfoAndBookmarksDialogType,
  PrioritizeAndDoNotPrioritizeDomainDialogType,
  PrioritizedNeed,
  Priority,
  SurveyDemographic,
  PriorityAreaCheck,
} from "../../types";
import {
  AddDomainPrioritiesRequest,
  ConfirmSurveyDeploymentRequest,
  ConfirmSurveyDeploymentResponse,
  DeletePriorityRequest,
  DeprioritizeDatasetRequest,
  GetDomainPrioritiesRequest,
  GetPrioritiesRequest,
  PrioritizeDatasetRequest,
  UpdateDomainPrioritiesDecisionRequest,
  UpdateDomainPrioritiesDecisionResponse,
  UpdateDomainPrioritiesRequest,
  UpsertPriorityRequest,
} from "../../api/needs/types";

export const getPrioritizedNeeds = createAsyncAction(
  "@needs/GET_PRIORITIZED_NEEDS_REQUEST",
  "@needs/GET_PRIORITIZED_NEEDS_SUCCESS",
  "@needs/GET_PRIORITIZED_NEEDS_FAILURE"
)<number, PrioritizedNeed[], Error>();

// is_priority_area_exists
export const checkPrioritizedArea = createAsyncAction(
  "@needs/CHECK_PRIORITIZED_AREA_REQUEST",
  "@needs/CHECK_PRIORITIZED_AREA_SUCCESS",
  "@needs/CHECK_PRIORITIZED_AREA_FAILURE"
)<number, PriorityAreaCheck, Error>();

export const prioritizeDataset = createAsyncAction(
  "@needs/PRIORITIZE_DATASET_REQUEST",
  "@needs/PRIORITIZE_DATASET_SUCCESS",
  "@needs/PRIORITIZE_DATASET_FAILURE"
)<PrioritizeDatasetRequest, PrioritizedNeed[], Error>();

export const deprioritizeDataset = createAsyncAction(
  "@needs/DEPRIORITIZE_DATASET_REQUEST",
  "@needs/DEPRIORITIZE_DATASET_SUCCESS",
  "@needs/DEPRIORITIZE_DATASET_FAILURE"
)<DeprioritizeDatasetRequest, number[], Error>();

export const getDomainPriorities = createAsyncAction(
  "@needs/GET_DOMAIN_PRIORITIES_REQUEST",
  "@needs/GET_DOMAIN_PRIORITIES_SUCCESS",
  "@needs/GET_DOMAIN_PRIORITIES_FAILURE"
)<GetDomainPrioritiesRequest, DomainPriority[], Error>();

export const realTimeDomainPriorityUpdate = createAction(
  "@needs/GET_REAL_TIME_DOMAIN_PRIORITY_UPDATE",
)<any>();

export const addDomainPriority = createAsyncAction(
  "@needs/ADD_DOMAIN_PRIORITY_REQUEST",
  "@needs/ADD_DOMAIN_PRIORITY_SUCCESS",
  "@needs/ADD_DOMAIN_PRIORITY_FAILURE"
)<AddDomainPrioritiesRequest, DomainPriority, Error>();

export const updateDomainPriority = createAsyncAction(
  "@needs/UPDATE_DOMAIN_PRIORITY_REQUEST",
  "@needs/UPDATE_DOMAIN_PRIORITY_SUCCESS",
  "@needs/UPDATE_DOMAIN_PRIORITY_FAILURE"
)<UpdateDomainPrioritiesRequest, DomainPriority, Error>();

export const realtimeUpdateDomainPriority = createAction(
  "@needs/REALTIME_UPDATE_DOMAIN_PRIORITY_SUCCESS"
)<{is_new: boolean, data: DomainPriority}>();

export const updateDomainPrioritiesDecision = createAsyncAction(
  "@needs/UPDATE_DOMAIN_PRIORITIES_DECISION_REQUEST",
  "@needs/UPDATE_DOMAIN_PRIORITIES_DECISION_SUCCESS",
  "@needs/UPDATE_DOMAIN_PRIORITIES_DECISION_FAILURE"
)<
  UpdateDomainPrioritiesDecisionRequest,
  UpdateDomainPrioritiesDecisionResponse,
  Error
>();

export const realtimeUpdateDomainPriorityDecision = createAction(
  "@needs/REALTIME_UPDATE_DOMAIN_PRIORITIES_DECISION_SUCCESS"
)<UpdateDomainPrioritiesDecisionResponse>();

export const confirmDomainPriorityStatus = createAsyncAction(
  "@needs/UPDATE_DOMAIN_PRIORITIES_CNF_STATUS_REQUEST",
  "@needs/UPDATE_DOMAIN_PRIORITIES_CNF_STATUS_SUCCESS",
  "@needs/UPDATE_DOMAIN_PRIORITIES_CNF_STATUS_FAILURE"
)<
  ConfirmSurveyDeploymentRequest,
  ConfirmSurveyDeploymentResponse,
  Error
>();
  // UpdateDomainPrioritiesDecisionRequest,
  // UpdateDomainPrioritiesDecisionResponse,

export const getPriorities = createAsyncAction(
  "@needs/GET_PRIORITIES_REQUEST",
  "@needs/GET_PRIORITIES_SUCCESS",
  "@needs/GET_PRIORITIES_FAILURE"
)<GetPrioritiesRequest, Priority[], Error>();

export const addPriority = createAsyncAction(
  "@needs/ADD_PRIORITY_REQUEST",
  "@needs/ADD_PRIORITY_SUCCESS",
  "@needs/ADD_PRIORITY_FAILURE"
)<
  UpsertPriorityRequest,
  { priority: Priority; localId?: number | undefined },
  Error
>();

export const updatePriority = createAsyncAction(
  "@needs/UPDATE_PRIORITY_REQUEST",
  "@needs/UPDATE_PRIORITY_SUCCESS",
  "@needs/UPDATE_PRIORITY_FAILURE"
)<UpsertPriorityRequest, Priority, Error>();

export const deletePriority = createAsyncAction(
  "@needs/DELETE_PRIORITY_REQUEST",
  "@needs/DELETE_PRIORITY_SUCCESS",
  "@needs/DELETE_PRIORITY_FAILURE"
)<DeletePriorityRequest, number, Error>();

export const initializePriorityAreasPriorities = createAction(
  "@needs/INITIALIZE_PRIORITY_AREAS_PRIORITIES"
)<Priority[]>();

export const addPriorityToPriorityAreas = createAction(
  "@needs/ADD_PRIORITY_TO_PRIORITY_AREAS"
)<Priority>();

export const changeSurveyDeploymentIdOnNeedsPage = createAction(
  "@needs/CHANGE_SURVEY_DEPLOYMENT_ID_ON_NEEDS_PAGE"
)<number | undefined>();

export const showItemLevelInfoAndBookmarksDialog = createAction(
  "@needs/SHOW_ITEM_LEVEL_INFO_AND_BOOKMARKS_DIALOG"
)<ItemLevelInfoAndBookmarksDialogType>();

export const showPrioritizeAndDoNotPrioritizeDomainDialog = createAction(
  "@needs/SHOW_PRIORITIZE_AND_DO_NOT_PRIORITIZE_DOMAIN_DIALOG"
)<PrioritizeAndDoNotPrioritizeDomainDialogType>();

export const hidePrioritizeAndDoNotPrioritizeDomainDialog = createAction(
  "@needs/HIDE_PRIORITIZE_AND_DO_NOT_PRIORITIZE_DOMAIN_DIALOG"
)<void>();

export const hideItemLevelInfoAndBookmarksDialog = createAction(
  "@needs/HIDE_ITEM_LEVEL_INFO_AND_BOOKMARKS_DIALOG"
)<void>();

export const changeHoveredDomainId = createAction(
  "@needs/CHANGE_HOVERED_DOMAIN_ID"
)<number | undefined>();

export const toggleDemographicHidden = createAction(
  "@needs/TOGGLE_DEMOGRAPHIC_HIDDEN"
)<SurveyDemographic>();

export const toggleDomainLockById = createAction(
  "@needs/TOGGLE_DOMAIN_LOCK_BY_ID"
)<number>();