import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import CreateEditNote from "./CreateEditNote";

// export const CreateEditNoteLayout = () => {
//   return (
//     <div><CreateEditNote /></div>
//   )
// }

import { useIntl } from "react-intl";
import { Icon } from "@blueprintjs/core";
// import './SciNoteComponents.scss';
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useLocation } from "react-router";
import { Activity, AttachedResource, Meeting } from "../../../../types";
import { isDistrictPlan } from "../../../../store/plans/selectors";
import {
  addResource,
  hideUpsertResourceDialog,
  showUpsertResourceDialog,
} from "../../../../store/resources/actions";
import { AppToaster } from "../../../../helpers/toaster";
import NoteAccordion from "./NoteAccordion";
import {
  Button,
  Checkbox,
  Classes,
  FormGroup,
  InputGroup,
} from "@blueprintjs/core";
import CollapsableActivity from "../../members/tabs/resources-tab/CollapsableActivity";
// import {
//   Activity,
//   AttachedToType,
//   DistrictSetupTaskKey,
//   MeetingTemplate,
//   MeetingType,
//   YEARS_OFFSET,
// } from "../../../../types";
import { Card, NonIdealState, Spinner } from "@blueprintjs/core";
import "./../../../team-plan/members/tabs/resources-tab/resourseTabs.scss";

type OwnProps = {};
type Props = OwnProps;

type LocationState = {
  data?: Partial<Meeting & { meetings: any[] }>;
};

// interface Activity {
//   id: number;
//   title: string;
//   // add other properties as needed
// }

// interface MeetingData {
//   activities: Activity[];
// }

const CreateEditNoteLayout: FunctionComponent<Props> = (props) => {
  // CONSTANTS
  const intl = useIntl();
  const location = useLocation<LocationState>();
  let meetingData = location.state?.data;

  let agenda;
  if (meetingData?.agenda) agenda = meetingData?.agenda;
  else if (meetingData?.meetings) agenda = meetingData?.meetings[0]?.agenda;

  const strippedAgenda = agenda?.replace(/<\/?[^>]+>/gi, ""); // Remove HTML tags

  const agendaItems = strippedAgenda
    ?.split("{{")
    .map((item: any) => item.replace("}}", "").trim())
    .filter((item: any) => item);
  const isInEditMode = useState(meetingData ? true : false);

  const activePlanId = useSelector((s) => s.plans.activePlan?.id);
  const [isSideMenuClosed, setIsSideMenuClosed] = useState<boolean>(true);

  const dispatch = useDispatch();
  const isDistrictActivePlan = useSelector(isDistrictPlan);

  const loading = useSelector((s) => s.activities.loading.getActivities);

  const handleResourceUpload = (
    resource: Partial<AttachedResource> & Pick<AttachedResource, "type">
  ) => {
    dispatch(
      showUpsertResourceDialog({
        resource: resource,
        onSave: handleResourceAdd,
        onClose: handleClose,
        isLoadingSelector: (s) => s.resources.loading.addResource,
        errorSelector: (s) => s.resources.errors.addResource,
      })
    );
  };

  const handleResourceAdd = useCallback(
    (
      resource: Partial<AttachedResource> &
        Pick<
          AttachedResource,
          "type" | "title" | "description" | "attached_to_type"
        >
    ) => {
      dispatch(
        addResource.request({
          resource: resource,
          planId: 105,
          onSuccess: () => handleResourceAddSuccess(resource),
        })
      );
    },
    []
  );
  const handleResourceAddSuccess = (
    resource: Partial<AttachedResource> &
      Pick<
        AttachedResource,
        "type" | "title" | "description" | "attached_to_type"
      >
  ) => {
    AppToaster.show({
      intent: "success",
      icon: "tick",
      message: intl.formatMessage(
        { id: "app.titles.toaster-message" },
        { name: resource.title }
      ),
    });
    handleClose();
  };

  const handleClose = () => {
    dispatch(hideUpsertResourceDialog());
  };

  const [agendas, activities, title] = useMemo(() => {
    const foundItems: any[] = [];
    let title : string | undefined = meetingData?.short_title;
    if(!title && meetingData?.meetings)
      title = meetingData?.meetings[0]?.short_title
    let activities: Activity[] | any[];
    if (meetingData?.activities) activities = meetingData?.activities;
    else if (meetingData?.meetings)
      activities = meetingData?.meetings[0]?.activities;
    else activities = [];

    if (agendaItems != undefined)
      agendaItems?.map((ai: any) => {
        const res = activities?.find(
          (et: any) => et?.name?.toLowerCase() === ai?.toLowerCase()
        );
        if (res) foundItems.push(res);
      });

    return [foundItems, activities, title];
  }, []);

  return (
    <div
      className={`planning d-flex ${isSideMenuClosed ? "collapse-toggle" : ""}`}
      style={{ overflow: "auto" }}
    >
      <div
        className={`${
          isSideMenuClosed ? "planning__left" : "planning__midd cols"
        }`}
      >
        <div className="sciMid-topBar">
          <div className="">
            <div className="agenda">
              {intl.formatMessage({
                id: "app.meetings.sci-writing.create-edit-notes-agenda",
              })}
            </div>
            <h2>{title??''}</h2>
          </div>
        </div>
        <div className="space-y-2 resourses-page__content" style={{ maxHeight: '400px', overflowY: 'auto' }}>
          {agendas?.map((ai: Activity, i) => {
            return <NoteAccordion key={i} activity={ai} newDesign={true} />;
          })}
        </div>
        <FormGroup
          className="resourcesLabel"
          label={intl
            .formatMessage({
              id: "app.titles.resources",
            })
            .toUpperCase()}
        >
          <div className="space-y-2 resourses-page__content" style={{ maxHeight: '400px', overflowY: 'auto' }}>
            {activities?.map((a: Activity | any, i) => {
              if (!agendaItems?.includes(a?.name))
                return <NoteAccordion key={i} activity={a} newDesign={true} />;
            })}
          </div>
        </FormGroup>
      </div>
      <div className="createNote_editor cols">
        <CreateEditNote
          meetingData={meetingData}
          isSideMenuClosed={isSideMenuClosed}
          setIsSideMenuClosed={setIsSideMenuClosed}
        />
      </div>
    </div>
  );
};

export default CreateEditNoteLayout;
