import { useDispatch, useSelector } from "react-redux";
import useGetTeamMembersLoading from "../../../helpers/hooks/loading/useGetTeamMembersLoading";
import { FunctionComponent, useEffect, useState } from "react";
import { DatasetMark, DomainPriority, UserModel } from "../../../types";
import _ from "lodash";
import { getDatasetMarks } from "../../../store/dataset-marks/actions";
import { getDomainPriorities } from "../../../store/needs/actions";

type OwnProps={
  activeSurveyDeploymentID?:number;
}

type Props =OwnProps;


export const useBeforeBeginningDialog= (props:Props) => {
  const dispatch = useDispatch();
  const {activeSurveyDeploymentID}=props;

  const survey_deployment_id = useSelector((s) => s.needs.surveyDeploymentId);
  const surveyDeploymentId = activeSurveyDeploymentID ? activeSurveyDeploymentID :survey_deployment_id;
  const teamMembers = useSelector((s) => s.teamMembers.teamMembers);
  const { } = useGetTeamMembersLoading();

  const [
    showBeforeBeginningDialog,
    setShowBeforeBeginningDialog,
  ] = useState<boolean>(false);

  const activePlan = useSelector((s) => s.plans.activePlan);

  const datasetMarks: DatasetMark[] = useSelector(
    (s) => s.datasetMarks.datasetMarks
  );

  const [beforeBeginningDialog, setBeforeBeginningDialog] = useState<{
    prioritiesLoaded: boolean;
    hasPriorities: boolean;
    datasetMarksLoaded: boolean;
    statuses: {
      user: UserModel;
      isDistrictPerson: boolean;
      bookmarksStarted: boolean;
    }[];
  }>({
    prioritiesLoaded: false,
    datasetMarksLoaded: false,
    hasPriorities: false,
    statuses: [],
  });

  useEffect(() => {
    if (teamMembers.length && beforeBeginningDialog.datasetMarksLoaded) {
      const membersStatuses = _.chain(teamMembers)
        .map((tM) => ({
          user: tM.user,
          isDistrictPerson: tM.plan === activePlan?.id ? false : true,
          bookmarksStarted: datasetMarks.some(
            (dM) =>
              dM.creator === tM.user.id &&
              dM.dataset.domain &&
              !dM.dataset.question &&
              !dM.dataset.is_grouped &&
              dM.dataset.data_points?.some(
                (dP) => dP.survey_deployment_id === surveyDeploymentId
              )
          ),
        }))
        .value();

      if (membersStatuses.length) {
        setBeforeBeginningDialog((s) => ({ ...s, statuses: membersStatuses }));
      } else {
        setBeforeBeginningDialog((s) => ({ ...s, statuses: [] }));
      }
    }
  }, [teamMembers, datasetMarks, surveyDeploymentId]);

  const onGetDomainPrioritiesComplete = (items?: DomainPriority[]) => {
    setBeforeBeginningDialog((s) => ({
      ...s,
      prioritiesLoaded: true,
      hasPriorities: !!items?.length,
    }));
  };

  const onGetDatasetMarksComplete = () => {
    setBeforeBeginningDialog((s) => ({
      ...s,
      datasetMarksLoaded: true,
    }));
  };

  useEffect(() => {
    if (surveyDeploymentId && activePlan?.school?.id) {
      setBeforeBeginningDialog((s) => ({
        ...s,
        datasetMarksLoaded: false,
      }));
      dispatch(
        getDatasetMarks.request({
          onComplete: onGetDatasetMarksComplete,
          schoolId: activePlan.school.id,
          is_team: true,
          survey_deployment_id: surveyDeploymentId,
          isFiltered: true
        })
      );
    }
  }, [surveyDeploymentId, activePlan?.school.id, dispatch]);

  useEffect(() => {
    if (surveyDeploymentId && activePlan?.id) {
      setBeforeBeginningDialog((s) => ({
        ...s,
        prioritiesLoaded: false,
      }));
      dispatch(
        getDomainPriorities.request({
          plan_id: activePlan?.id,
          onComplete: onGetDomainPrioritiesComplete,
          survey_deployment_id: surveyDeploymentId
        })
      );
    }
  }, [surveyDeploymentId, activePlan?.id, dispatch]);

  return {
    show: showBeforeBeginningDialog,
    onOpen: () => setShowBeforeBeginningDialog(true),
    onClose: () => setShowBeforeBeginningDialog(false),
    beforeBeginningDialog,
  };
};

export default useBeforeBeginningDialog;