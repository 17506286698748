import React, { ChangeEvent, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Icon, InputGroup, Spinner } from '@blueprintjs/core';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import './AddGoal.scss';
import { useDispatch, useSelector } from 'react-redux';
import { createGoal, triggerPusherEvent } from '../../../../store/goals/actions';
import { NewGoalState, SurveyDeploymentStateEnum } from '../../../../types';
import { getSurveyDeploymentsBySchool } from '../../../../store/surveys-deployment/actions';
import { Button } from '@mui/material';
import { getActivePlanId } from '../../../../store/plans/selectors';
import { useIntl } from 'react-intl';

type OwnProps = {
  pusher?: any;
};

type Props = OwnProps;

const AddGoal: FunctionComponent<Props> = ({ pusher }) => {

  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const intl=useIntl();
  const { goal } = useSelector((s): NewGoalState => s.goals.newGoalState);
  const [goalName, setGoalName] = useState<string>("");
  const SHORT_TITLE_LIMIT = 100;
  const activePlanId = useSelector((s) => s.plans.activePlan?.id);


  const surveyDeployments = useSelector(
    (s) => s.surveysDeployment.surveysDeploymentBySchool
  );

  const surveyDeploymentLoading = useSelector((s)=> s.surveysDeployment.loading.getSurveyDeploymentsBySchool)

  const currentUser = useSelector((s) => s.auth.authenticatedUser);
  const plan = useSelector((s) => s.plans.activePlan);
  const planId = plan?.school.id
  const handleGoalNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    value.length <= SHORT_TITLE_LIMIT && setGoalName(value);

  };

  useEffect(() => {
    if (planId) {
      dispatch(getSurveyDeploymentsBySchool.request(planId));
    }
  }, [planId, dispatch]);

  const handleClickOpen2 = () => {
    surveyDeploymentLoading === false &&  setOpen(true);
  };

  const handleClose2 = () => {
    setOpen(false);
  };

  const mostRecentDeployment = useMemo(() => {
    return surveyDeployments.find((sd) => {
      if (sd.state === SurveyDeploymentStateEnum.READY  && sd.is_deleted != true) {
        return sd;
      }
    });
  }, [surveyDeployments]);
  const inputTextCheck = useMemo(() => {
    if (goalName) {
      return true;
    }
  }, [goalName]);

  const handleFormSubmit = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const goalData = {
      ...goal,
      statement: goalName,
      is_active: false,
      planId: plan?.id,
      survey_deployment: mostRecentDeployment,
      targets: [],
      selected_domains: [],
      desired_outcome: "",
      is_completed: false,
      priority_areas: [],
      user: currentUser?.id,
    }
    
    dispatch(
      createGoal.request(goalData)
    );

  
    // dispatch(triggerPusherEvent.request({
    //   plan_id: activePlanId,
    //   event: 'new_goal_added',
    //   channel_name: 'presence-sp-'+activePlanId,
    //   data: {'sp':'new data sample'}
    // }))
    handleClose2();
    setGoalName("");
  };

  return (
    <>
      {!!mostRecentDeployment && surveyDeployments.length ? (
        <Tooltip title="Add Goal" arrow>
          <button onClick={handleClickOpen2} className="nj-btn addBtn">
           {surveyDeploymentLoading ? <Spinner intent='none' size={20} /> : (
  <>
    <Icon icon="small-plus" iconSize={20} /> goal
  </>
)}
          </button>
        </Tooltip>
      ) : (
        <Tooltip
          title={intl.formatMessage({id:"app.warning.disabled.create-goal"})}
          arrow
        >
          <span>
            <Button disabled className="nj-btn">
              <Icon icon="small-plus" iconSize={20} /> goal
            </Button>
          </span>
        </Tooltip>
      )}

      <Dialog
        className="create-goal"
        open={open}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="create-goal-heading">
          <h3>{intl.formatMessage({id:"app.titles.create-goal-name"})}</h3>
          <button onClick={handleClose2}>
            <Icon icon="cross" iconSize={18} />
          </button>
        </div>
        <div className="search-label">
        <p className='most-recent-survey'>
          {intl.formatMessage({ id: "app.goals.new-goal.steps.goal-tittle" },
           {
            sureveyName:`${mostRecentDeployment?.survey?.name +
                " " +
                mostRecentDeployment?.deployment_name}`,  
            b: (a) => <strong>{a}</strong>
          }   
        )}</p>
          <InputGroup
            disabled={false}
            value={goalName}
            onChange={handleGoalNameChange}
            placeholder={intl.formatMessage({id:"app.goals.new-goal.steps.name-of-goal"})}
          />
          <span className="max-length-right">(Max {SHORT_TITLE_LIMIT - goalName.length}  Characters
            {goalName.length !== 0 && " left"})</span>
        </div>

        <div className="button-group">
          <button onClick={handleClose2} className="stroke-btn">
            Cancel
          </button>
          <button
            disabled={!inputTextCheck}
            onClick={handleFormSubmit}
            className="nj-btn"
          >
            Publish
          </button>
        </div>
      </Dialog>
    </>
  );
};

export default AddGoal;