import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, IconButton } from "@material-ui/core";
import { Button, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useIntl } from "react-intl";
import { isDistrictPlan } from "../../../../store/plans/selectors";
import {  useSelector } from "react-redux";
import useUserRole from "../../../../helpers/hooks/useUserRole";
import { UserRole } from "../../../../types";




type OwnProps = {
    openDeleteDeploymentDialog:any,
    setOpenDeleteDeploymentDialog:any,
    onSurveyDeploymentSoftDelete:any,
    onSurveyDeploymentDeleteConfirmed:any,
    isSoftDelete:boolean,
    text:any,
    isManagedDeletedDataWorkspace?:boolean

    
  };
  
  type Props = OwnProps;

  

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(0)
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1)
    }, "& .MuiPaper-root": {
      width: "40%", // Set the desired width here
      maxWidth: "100%", // Adjust max-width to make it responsive
    },
  }));



const DeleteSurveyDeploymentDialog:React.FC<Props>=(props)=> {

    const {
      openDeleteDeploymentDialog,
      setOpenDeleteDeploymentDialog,
      onSurveyDeploymentSoftDelete,
      isSoftDelete,
      onSurveyDeploymentDeleteConfirmed,
      text,
      isManagedDeletedDataWorkspace
    } = props;
  const intl = useIntl();  
  const isDistrictActivePlan = useSelector(isDistrictPlan);
  const { role,isSuperintendent,isPrincipal } = useUserRole();
  
  const [deleteDeploymentStep2,setDeleteDeploymentStep2] = useState(false); 

    const handleClose = () => {
      setOpenDeleteDeploymentDialog({ open: false, surveyDeploymentId: null });
      setDeleteDeploymentStep2(false)
    };

    const onSurveyDeploymentDelete = (surveyDeploymentId: number) => {
        if (isSoftDelete && !isManagedDeletedDataWorkspace  ) {
          
            onSurveyDeploymentSoftDelete(surveyDeploymentId);
            
          } else {
            if (isDistrictActivePlan) {
              onSurveyDeploymentDeleteConfirmed(surveyDeploymentId);
            } else {
             onSurveyDeploymentSoftDelete(surveyDeploymentId);
            }
          }

      handleClose();
    };
  
return (
  <BootstrapDialog
    onClose={handleClose}
    aria-labelledby="customized-dialog-title"
    open={openDeleteDeploymentDialog?.open}
    maxWidth="sm"
  >
      <div style={{ paddingRight : "10px",paddingTop : "10px" ,display: "flex", justifyContent: "end" }}><CloseIcon onClick={handleClose} aria-label="close" /></div> 
    <div className="delete-modal">
        <div className="delete-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="29" viewBox="0 0 33 36" fill="none">
            <path
              d="M25.3908 35.1585C27.0475 35.1585 28.399 33.8505 28.4424 32.1941L29.2709 7.69273H32.1045V5.07677H23.3416V3.63808C23.3416 1.89437 21.9463 0.499115 20.2026 0.499115H12.0064C10.2627 0.499115 8.86743 1.89437 8.86743 3.63808V5.07677H0.104492V7.69273H2.93812L3.76654 32.1941C3.81003 33.8508 5.16179 35.1585 6.81823 35.1585H25.3908ZM11.4834 3.68168C11.4834 3.37635 11.7014 3.15861 12.0065 3.15861H20.2026C20.508 3.15861 20.7257 3.37665 20.7257 3.68168V5.12037H11.4832L11.4834 3.68168ZM6.38258 32.1067L5.55416 7.69258H26.5678L25.7394 32.1067C25.7394 32.3248 25.5213 32.5428 25.3033 32.5428L6.81826 32.5425C6.60052 32.5425 6.42596 32.3683 6.38248 32.1067H6.38258Z"
              fill="black"
            />
            <path d="M14.7969 13.4909H17.4128V26.5264H14.7969V13.4909Z" fill="black" />
            <path d="M9.56543 13.4909H12.1814V26.5264H9.56543V13.4909Z" fill="black" />
            <path d="M20.0283 13.4909H22.6443V26.5264H20.0283V13.4909Z" fill="black" />
          </svg>
        </div>
       
        <>
        <h5>{intl.formatMessage({ id: "app.titles.delete-survey-deployment" })}</h5>
        {deleteDeploymentStep2 ? intl.formatMessage({
          id: `app.confirm-dialog${
            isManagedDeletedDataWorkspace ? ".manage-deleted" : ""
          }.survey-deployment-soft-delete.second-step`,
        }) : text}
      
        </>
      
      <DialogActions style={{ display: "flex", justifyContent: "center" }}>
        <Button autoFocus className="btn btn-secondary" onClick={handleClose}>
          {deleteDeploymentStep2?intl.formatMessage({ id: "app.titles.no" }):intl.formatMessage({ id: "app.titles.cancel" })}
        </Button>
        <Button
          autoFocus
          className="btn btn-primary"
          onClick={() => deleteDeploymentStep2 ? onSurveyDeploymentDelete(openDeleteDeploymentDialog?.surveyDeploymentId): setDeleteDeploymentStep2(true)}
        >
          {deleteDeploymentStep2?isManagedDeletedDataWorkspace && isSuperintendent?intl.formatMessage({
          id: "app.confirm-dialog.survey-deployment-soft-delete.confirm",
        }):(isSuperintendent||isPrincipal) ? intl.formatMessage({id:"app.titles.yes.delete"}):"" :intl.formatMessage({ id: "app.titles.continue" })}
        </Button>
      </DialogActions>
    </div>
  </BootstrapDialog>
);
}

export default DeleteSurveyDeploymentDialog;